import { createAction } from 'redux-actions';

export const getCustomerTagsRequest = createAction('GET_CUSTOMER_TAGS_REQUEST');
export const getCustomerTagsSuccess = createAction('GET_CUSTOMER_TAGS_SUCCESS');
export const getCustomerTagsFailure = createAction('GET_CUSTOMER_TAGS_FAILURE');
export const addTagRequest = createAction('ADD_TAG_REQUEST');
export const addTagSuccess = createAction('ADD_TAG_SUCCESS');
export const addTagFailure = createAction('ADD_TAG_FAILURE');
export const deleteTagRequest = createAction('DELETE_TAG_REQUEST');
export const deleteTagSuccess = createAction('DELETE_TAG_SUCCESS');
export const deleteTagFailure = createAction('DELETE_TAG_FAILURE');
export const updateTagRequest = createAction('UPDATE_TAG_REQUEST');
export const updateTagSuccess = createAction('UPDATE_TAG_SUCCESS');
export const updateTagFailure = createAction('UPDATE_TAG_FAILURE');
export const getCustomersWithTagsRequest = createAction(
  'GET_CUSTOMERS_WITH_TAG_REQUEST'
);
export const getCustomersWithTagsSuccess = createAction(
  'GET_CUSTOMERS_WITH_TAG_SUCCESS'
);
export const getCustomersWithTagsFailure = createAction(
  'GET_CUSTOMERS_WITH_TAG_FAILURE'
);
export const assignTagsToCustomerRequest = createAction(
  'ASSIGN_TAGS_TO_CUSTOMER_REQUEST'
);
export const assignTagsToCustomerSuccess = createAction(
  'ASSIGN_TAGS_TO_CUSTOMER_SUCCESS'
);
export const assignTagsToCustomerFailure = createAction(
  'ASSIGN_TAGS_TO_CUSTOMER_FAILURE'
);
