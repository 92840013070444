import { handleActions } from 'redux-actions';

import {
  createCiscoAPSFailure,
  createCiscoAPSRequest,
  createCiscoAPSSuccess,
  deleteCiscoAPSFailure,
  deleteCiscoAPSRequest,
  deleteCiscoAPSSuccess,
  getAPSDataFailure,
  getAPSDataRequest,
  getAPSDataSuccess,
  getTenantsSelectOptionsFailure,
  getTenantsSelectOptionsRequest,
  getTenantsSelectOptionsSuccess,
  updateCiscoAPSFailure,
  updateCiscoAPSRequest,
  updateCiscoAPSSuccess,
} from './actions';

const initialState = {
  apsData: [],
  apsCount: 0,
  isCreatingCiscoAPS: false,
  isCreatingCiscoAPSSuccess: false,
  isCreatingCiscoAPSFailure: false,
  errorMessage: '',
  successMessage: '',
  isGettingAPSData: true,
  isGettingAPSDataSuccess: false,
  isGettingAPSDataFailure: false,
  isGettingTenantsSelectOptions: false,
  tenantOptions: [],
  isUpdatingCiscoAPS: false,
  isUpdatingCiscoAPSSuccess: false,
  isUpdatingCiscoAPSFailure: false,
  isDeletingCiscoAPS: false,
  isDeletingCiscoAPSSuccess: false,
  isDeletingCiscoAPSFailure: false,
};

const reducer = handleActions(
  {
    [createCiscoAPSRequest]: (state) => ({
      ...state,
      isCreatingCiscoAPS: true,
      isCreatingCiscoAPSSuccess: false,
      isCreatingCiscoAPSFailure: false,
    }),
    [createCiscoAPSSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingCiscoAPS: false,
      isCreatingCiscoAPSSuccess: true,
      successMessage: payload.message,
      apsData: [...state.apsData, payload.data],
    }),
    [createCiscoAPSFailure]: (state, { payload }) => ({
      ...state,
      isCreatingCiscoAPS: false,
      isCreatingCiscoAPSFailure: true,
      errorMessage: payload,
    }),
    [getAPSDataRequest]: (state) => ({
      ...state,
      isGettingAPSData: true,
      isGettingAPSDataSuccess: false,
      isGettingAPSDataFailure: false,
    }),
    [getAPSDataSuccess]: (state, { payload }) => ({
      ...state,
      isGettingAPSData: false,
      isGettingAPSDataSuccess: true,
      successMessage: payload.message,
      apsData: payload.data.rows,
      apsCount: payload.data.count,
    }),
    [getAPSDataFailure]: (state, { payload }) => ({
      ...state,
      isGettingAPSData: false,
      isGettingAPSDataFailure: true,
      errorMessage: payload,
    }),
    [getTenantsSelectOptionsRequest]: (state) => ({
      ...state,
      isGettingTenantsSelectOptions: true,
    }),
    [getTenantsSelectOptionsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingTenantsSelectOptions: false,
      tenantOptions: payload.data,
    }),
    [getTenantsSelectOptionsFailure]: (state, { payload }) => ({
      ...state,
      isGettingTenantsSelectOptions: false,
      errorMessage: payload,
    }),
    [updateCiscoAPSRequest]: (state) => ({
      ...state,
      isUpdatingCiscoAPS: true,
      isUpdatingCiscoAPSSuccess: false,
      isUpdatingCiscoAPSFailure: false,
    }),
    [updateCiscoAPSSuccess]: (state, { payload }) => {
      const apsDataCopy = [...state.apsData];
      const index = apsDataCopy.findIndex(
        (item) => item.id === payload.data.id
      );

      if (index !== -1) {
        apsDataCopy[index] = payload.data;
      }

      return {
        ...state,
        isUpdatingCiscoAPS: false,
        isUpdatingCiscoAPSSuccess: true,
        successMessage: payload.message,
        apsData: apsDataCopy,
      };
    },
    [updateCiscoAPSFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingCiscoAPS: false,
      isUpdatingCiscoAPSFailure: true,
      errorMessage: payload,
    }),
    [deleteCiscoAPSRequest]: (state) => ({
      ...state,
      isDeletingCiscoAPS: true,
      isDeletingCiscoAPSSuccess: false,
      isDeletingCiscoAPSFailure: false,
    }),
    [deleteCiscoAPSSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingCiscoAPS: false,
      isDeletingCiscoAPSSuccess: true,
      successMessage: payload.message,
      apsData: state.apsData.filter((aps) => aps.id !== payload.data),
    }),
    [deleteCiscoAPSFailure]: (state, { payload }) => ({
      ...state,
      isDeletingCiscoAPS: false,
      isDeletingCiscoAPSFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
