import { lazy } from 'react';

import Loadable from 'components/Loadable';

const DashboardDefault = Loadable(
  lazy(() => import('pages/dashboard/default'))
);
const CustomerProfilePage = Loadable(
  lazy(() => import('pages/customers/profile'))
);
const LineUsagePage = Loadable(
  lazy(() => import('pages/customers/editPages/usage'))
);
const Tenants = Loadable(lazy(() => import('pages/admin/tenants/index')));
const TenantsProfile = Loadable(
  lazy(() => import('pages/admin/tenants/profile'))
);
const AuthGuard = Loadable(lazy(() => import('utils/route-guard/AuthGuard')));
const Error404 = Loadable(lazy(() => import('pages/maintenance/404')));
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')));
const Notifications = Loadable(lazy(() => import('pages/notifications')));
const MasterAgent = Loadable(lazy(() => import('pages/agents/masterAgent')));
const SubAgent = Loadable(lazy(() => import('pages/agents/subAgent')));
const MailingList = Loadable(lazy(() => import('pages/mailingList')));
const MasterAgentProfile = Loadable(
  lazy(() => import('pages/agents/masterAgent/profile'))
);
const SubAgentProfile = Loadable(
  lazy(() => import('pages/agents/subAgent/profile'))
);
const CreateOrEditMasterAgent = Loadable(
  lazy(() => import('pages/agents/masterAgent/createOrEdit'))
);
const CreateOrEditSubAgent = Loadable(
  lazy(() => import('pages/agents/subAgent/createOrEdit'))
);
const Customers = Loadable(lazy(() => import('pages/customers')));
const DraftAcpCustomers = Loadable(lazy(() => import('pages/customers/draft')));
const ShopifyCustomers = Loadable(
  lazy(() => import('pages/customers/shopify'))
);
const CreateCustomer = Loadable(lazy(() => import('pages/customers/create')));
const PortIn = Loadable(lazy(() => import('pages/portIn/index')));
const EditCustomer = Loadable(lazy(() => import('pages/customers/edit')));
const TicketsList = Loadable(lazy(() => import('pages/tickets/index')));
const Acp = Loadable(lazy(() => import('pages/customers/acp/index')));
const ApplyForAcp = Loadable(
  lazy(() => import('pages/customers/acp/ApplyForAcp/index'))
);
const PaymentSucceeded = Loadable(
  lazy(() => import('pages/customers/components/checkout/PaymentSucceeded'))
);

const Users = Loadable(lazy(() => import('pages/settings/users')));
const UsersProfile = Loadable(
  lazy(() => import('pages/settings/users/components/profile'))
);
const Roles = Loadable(lazy(() => import('pages/settings/roles')));
const EditUser = Loadable(lazy(() => import('pages/settings/users/editPages')));
const TicketsGroup = Loadable(lazy(() => import('pages/tickets/group')));
const SimCardSettings = Loadable(
  lazy(() => import('pages/settings/simCardSettings/index'))
);
const SIMCards = Loadable(lazy(() => import('pages/eCommerce/simCards')));
const MDNs = Loadable(lazy(() => import('pages/admin/mdns')));
const AdminCarrier = Loadable(lazy(() => import('pages/admin/carrier')));
const Vendors = Loadable(lazy(() => import('pages/eCommerce/vendors')));
const Categories = Loadable(lazy(() => import('pages/eCommerce/categories')));
const Manufacturers = Loadable(
  lazy(() => import('pages/eCommerce/manufacturers'))
);
const Devices = Loadable(lazy(() => import('pages/eCommerce/devices')));
const DevicesCreate = Loadable(
  lazy(() => import('pages/eCommerce/devices/components/createDevice'))
);

const DeviceEdit = Loadable(
  lazy(() => import('pages/eCommerce/devices/components/editDevice'))
);

const Promotions = Loadable(lazy(() => import('pages/eCommerce/promotions')));

const UnderConstruction = Loadable(
  lazy(() => import('../pages/maintenance/under-construction'))
);

const Accessories = Loadable(
  lazy(() => import('../pages/eCommerce/accessories'))
);

const AccessoryCreate = Loadable(
  lazy(() =>
    import('../pages/eCommerce/accessories/components/createAccessories')
  )
);
const AccessoryEdit = Loadable(
  lazy(() =>
    import('../pages/eCommerce/accessories/components/editAccessories')
  )
);
const Inventory = Loadable(lazy(() => import('../pages/eCommerce/inventory')));

const InventoryVariantEdit = Loadable(
  lazy(() => import('../pages/eCommerce/inventory/components/variantEdit'))
);

const RetailPlans = Loadable(
  lazy(() => import('../pages/settings/plans/index'))
);

const CreateOrEditRetailPlan = Loadable(
  lazy(() =>
    import('../pages/settings/plans/retailPlans/createOrEditRetailPlan')
  )
);
const CreateOrEditTopUp = Loadable(
  lazy(() => import('../pages/settings/plans/topUps/createOrdEditTopUp'))
);

const CarrierSettings = Loadable(
  lazy(() => import('../pages/settings/carrierSettings/index'))
);

const Carrier = Loadable(
  lazy(() => import('../pages/settings/carrierSettings/components/Carrier'))
);
const Shipping = Loadable(
  lazy(() => import('../pages/settings/carrierSettings/shipping/index'))
);

const SubscriberReport = Loadable(lazy(() => import('../pages/report')));

const TransactionReport = Loadable(
  lazy(() => import('../pages/report/TransactionReport'))
);

const SnapshotReport = Loadable(
  lazy(() => import('../pages/report/SnapshotReport'))
);

const RecertificationReport = Loadable(
  lazy(() => import('../pages/report/RecertificationReport'))
);

const NotProcessedReport = Loadable(
  lazy(() => import('../pages/report/NotProcessedReport'))
);
const Hotlines = Loadable(lazy(() => import('../pages/report/hotlines')));
const CanceledOrders = Loadable(
  lazy(() => import('../pages/report/canceledOrders'))
);
const DeactivatedLines = Loadable(
  lazy(() => import('../pages/report/deactivatedLines'))
);
const AbandonedAndFailureOrdersReport = Loadable(
  lazy(() => import('../pages/report/abandonedAndFailureOrdersReport'))
);
const AgentOrders = Loadable(
  lazy(() => import('../pages/eCommerce/orders/agentOrders'))
);
const CustomerOrders = Loadable(
  lazy(() => import('../pages/eCommerce/orders/customerOrders'))
);
const ECommerceSettings = Loadable(
  lazy(() => import('../pages/settings/eCommerceSettings'))
);
const PaymentMethods = Loadable(
  lazy(() => import('../pages/billing/paymentMethods'))
);
const PaymentsList = Loadable(
  lazy(() => import('../pages/billing/paymentsList'))
);
const Subscriptions = Loadable(
  lazy(() => import('../pages/billing/subscriptions'))
);
const SubscriptionDetails = Loadable(
  lazy(() => import('../pages/billing/subscriptions/subscriptionStatus'))
);
const EmbeddedForm = Loadable(
  lazy(() => import('../pages/billing/subscriptions/embeddedForm'))
);

const Shipment = Loadable(
  lazy(() => import('../pages/eCommerce/orders/agentOrders/shipment'))
);

const SiteSettings = Loadable(
  lazy(() => import('../pages/settings/siteSettings'))
);

const CustomerOrderFulfill = Loadable(
  lazy(() => import('../pages/eCommerce/orders/customerOrders/fulfill'))
);

const CustomerShopifyOrderFulfill = Loadable(
  lazy(() =>
    import('../pages/eCommerce/orders/customerOrders/fulfillShopifyOrder')
  )
);
const CreateEditRetailPlan = Loadable(
  lazy(() =>
    import(
      '../pages/settings/carrierSettings/components/wholesalePlans/createEditRetailPlan'
    )
  )
);

const Optimization = Loadable(
  lazy(() => import('../pages/report/optimization/Optimization'))
);

const PLReports = Loadable(lazy(() => import('../pages/report/profitLoss')));

const APS = Loadable(
  lazy(() => import('../pages/report/optimization/aps/APS'))
);
const CDRs = Loadable(lazy(() => import('../pages/report/cdr/cdrReport')));

const ActiveLinesWithNoSubscription = Loadable(
  lazy(() => import('../pages/report/activeLinesWithNoSubscription'))
);

const EditMainBanner = Loadable(
  lazy(() => import('../pages/settings/editMainBanner'))
);

const EmailMarketing = Loadable(
  lazy(() => import('../pages/settings/emailMarketing'))
);

const MainRoutes = [
  {
    path: '/',
    type: 'collapse',
    breadcrumbs: true,
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'dashboard',
        resource: ['all', 'admin'],
        type: 'collapse',
        breadcrumbs: true,
        url: '/dashboard',
        title: 'Dashboard',
        children: [
          {
            path: 'default',
            type: 'item',
            element: <DashboardDefault />,
            breadcrumbs: true,
            url: '/dashboard/default',
            title: 'Default',
          },
          {
            path: 'ports',
            type: 'item',
            resource: ['tenant', 'MasterAgent', 'subAgent'],
            element: <PortIn />,
            breadcrumbs: true,
            url: '/dashboard/ports',
            title: 'Ports',
          },
        ],
      },
      {
        path: 'tenants',
        resource: ['admin'],
        type: 'collapse',
        breadcrumbs: true,
        url: '/tenants',
        title: 'Tenants',
        children: [
          {
            path: 'list',
            element: <Tenants />,
            url: '/tenants/list',
            type: 'item',
            title: 'List',
          },
          {
            path: 'profile',
            element: <TenantsProfile />,
            url: '/tenants/profile',
            title: 'profile',
            type: 'item',
          },
        ],
      },
      {
        path: 'admin',
        resource: ['admin'],
        type: 'collapse',
        breadcrumbs: true,
        url: '/admin',
        title: 'Phone Numbers',
        children: [
          {
            path: 'phone-numbers',
            element: <MDNs />,
            url: '/admin/phone-numbers',
            breadcrumbs: true,
            title: 'Phone Numbers',
            type: 'item',
          },
          {
            path: 'carrier',
            element: <AdminCarrier />,
            url: '/admin/carrier',
            breadcrumbs: true,
            title: 'Carriers',
            type: 'item',
          },
        ],
      },
      {
        path: 'customers',
        resource: ['tenant', 'MasterAgent', 'subAgent'],
        type: 'collapse',
        breadcrumbs: true,
        url: '/customers',
        title: 'Customers',
        children: [
          {
            path: 'apply-for-acp',
            element: <ApplyForAcp />,
            url: '/customers/apply-for-acp',
            title: 'Apply for ACP',
            type: 'item',
          },
          {
            path: 'payment-succeeded',
            element: <PaymentSucceeded />,
            url: '/customers/payment-succeeded',
            title: 'Payment Succeeded',
            type: 'item',
          },
          {
            path: 'list/:type',
            element: <Customers />,
            url: '/customers/list',
            title: 'List',
            type: 'item',
          },
          {
            path: 'list/drafts',
            element: <DraftAcpCustomers />,
            url: '/customers/list/drafts',
            title: 'Drafts List',
            type: 'item',
          },
          {
            path: 'list/shopify',
            element: <ShopifyCustomers />,
            url: '/customers/list/shopify',
            title: 'Shopify Customers List',
            type: 'item',
          },
          {
            path: 'create/:type',
            element: <CreateCustomer />,
            url: '/customers/create',
            title: 'Create',
            type: 'item',
          },
          {
            path: 'profile/:id',
            element: <EditCustomer />,
            url: '/customers/profile',
            title: 'Profile',
            type: 'item',
          },
          {
            path: 'acp/:id',
            element: <Acp />,
            url: '/customers/acp',
            title: 'ACP',
            type: 'item',
          },
          {
            path: 'profile',
            element: <CustomerProfilePage />,
            url: '/customers/profile',
            title: 'Profile',
            type: 'item',
          },
          {
            path: 'profile/usage/:customerId/:lineId',
            element: <LineUsagePage />,
            url: '/customers/profile/usage/',
            title: 'Usage Data',
            type: 'item',
          },
        ],
      },
      {
        path: 'agents',
        resource: ['tenant', 'MasterAgent'],
        type: 'collapse',
        breadcrumbs: true,
        url: '/agents',
        title: 'Agents',
        children: [
          {
            path: 'master-agents',
            element: <MasterAgent />,
            url: '/agents/master-agents',
            title: 'Master Agents',
            type: 'item',
          },
          {
            path: 'sub-agents',
            element: <SubAgent />,
            url: '/agents/sub-agents',
            title: 'Sub Agents',
            type: 'item',
          },
          {
            path: 'master-agent/create',
            element: <CreateOrEditMasterAgent />,
            url: '/agents/master-agent/create',
            title: 'Create Master Agent',
            type: 'item',
          },
          {
            path: 'master-agent/profile',
            element: <MasterAgentProfile />,
            url: '/agents/master-agent/profile',
            title: 'Profile',
            type: 'item',
          },
          {
            path: 'master-agent/edit/:id',
            element: <CreateOrEditMasterAgent />,
            url: '/agents/master-agent/edit',
            title: 'Edit Master Agent',
            type: 'item',
          },
          {
            path: 'sub-agent/create',
            element: <CreateOrEditSubAgent />,
            url: '/agents/sub-agent/create',
            title: 'Create Sub Agent',
            type: 'item',
          },
          {
            path: 'sub-agent/profile',
            element: <SubAgentProfile />,
            url: '/agents/sub-agent/profile',
            title: 'Profile',
            type: 'item',
          },
          {
            path: 'sub-agent/edit/:id',
            element: <CreateOrEditSubAgent />,
            url: '/agents/sub-agent/edit',
            title: 'Edit Sub Agent',
            type: 'item',
          },
        ],
      },
      {
        path: '404',
        resource: ['all'],
        element: <Error404 />,
        type: 'item',
        breadcrumbs: true,
        url: '/404',
        title: 'Page Not Found',
      },
      {
        path: 'notifications',
        resource: ['admin'],
        element: <Notifications />,
        type: 'item',
        breadcrumbs: true,
        url: '/notifications',
        title: 'Notifications',
      },
      {
        path: 'tickets/list',
        resource: ['tenant', 'all'],
        element: <TicketsList />,
        type: 'item',
        url: '/tickets/list',
        title: 'Tickets List',
      },
      {
        path: 'mailing/list',
        resource: ['tenant'],
        element: <MailingList />,
        type: 'item',
        url: '/mailing/list',
        title: 'Mailing List',
      },
      {
        path: 'report',
        resource: ['tenant', 'masterAgent', 'subAgent'],
        type: 'collapse',
        breadcrumbs: true,
        url: '/report',
        title: 'Reports',
        children: [
          {
            path: 'subscriber-report',
            element: <SubscriberReport />,
            url: '/report/subscriber-report',
            title: 'Subscriber Report',
            type: 'item',
          },
          {
            path: 'transaction-report',
            element: <TransactionReport />,
            url: '/report/transaction-report',
            title: 'Transaction Report',
            type: 'item',
          },
          {
            path: 'snapshot-report',
            element: <SnapshotReport />,
            url: '/report/snapshot-report',
            title: 'Snapshot Report',
            type: 'item',
          },
          {
            path: 'recertification-report',
            element: <RecertificationReport />,
            url: '/report/recertification-report',
            title: 'Recertification Report',
            type: 'item',
          },
          {
            path: 'not-processed-report',
            element: <NotProcessedReport />,
            url: '/report/not-processed-report',
            title: 'Not Processed Report',
            type: 'item',
          },
          {
            path: 'hotlines',
            element: <Hotlines />,
            url: '/report/hotlines',
            title: 'Hotlines Report',
            type: 'item',
          },
          {
            path: 'canceled',
            element: <CanceledOrders />,
            url: '/report/canceled',
            title: 'Canceled Orders Report',
            type: 'item',
          },
          {
            path: 'deactivated-lines',
            element: <DeactivatedLines />,
            url: '/report/deactivated-lines',
            title: 'Deactivated Lines',
            type: 'item',
          },
          {
            path: 'abandoned-failed-orders',
            element: <AbandonedAndFailureOrdersReport />,
            url: '/report/abandoned-failed-orders',
            title: 'Abandoned & Failed orders',
            type: 'item',
          },
          {
            path: 'active-lines-with-no-subscription',
            element: <ActiveLinesWithNoSubscription />,
            url: '/report/active-lines-with-no-subscription',
            title: 'Active Lines With No Subscription',
            type: 'item',
          },
          {
            path: 'optimization',
            element: <Optimization />,
            url: '/report/optimization',
            title: 'Optimization',
            type: 'item',
          },
          {
            path: 'profit-loss',
            element: <PLReports />,
            url: '/report/profit-loss',
            title: 'Profit & Loss',
            type: 'item',
          },
          {
            path: 'optimization/aps/:id',
            element: <APS />,
            url: '/report/optimization/aps/:id',
            title: 'APS',
            type: 'item',
          },
          {
            path: 'cdr',
            element: <CDRs />,
            url: '/report/cdr',
            title: 'CDR Report',
            type: 'item',
          },
        ],
      },
      {
        path: 'settings',
        resource: ['tenant'],
        type: 'collapse',
        breadcrumbs: true,
        url: '/settings',
        title: 'Settings',
        children: [
          {
            path: 'users-settings',
            element: <Users />,
            url: '/settings/users-settings',
            title: 'User Settings',
            type: 'item',
          },
          {
            path: 'user-profile',
            element: <UsersProfile />,
            url: '/settings/user-profile',
            title: 'User Profile',
            type: 'item',
          },
          {
            path: 'users-roles',
            element: <Roles />,
            url: '/settings/users-roles',
            title: 'User Roles',
            type: 'item',
          },
          {
            path: 'users-edit/:id',
            element: <EditUser />,
            url: '/settings/users-edit',
            title: 'User Edit',
            type: 'item',
          },
          {
            path: 'e-commerce-settings',
            element: <ECommerceSettings />,
            url: '/settings/e-commerce-settings',
            title: 'E-commerce Settings',
            type: 'item',
          },
          {
            path: 'tickets-groups',
            element: <TicketsGroup />,
            url: '/settings/tickets-groups',
            title: 'Ticket Groups',
            type: 'item',
          },
          {
            path: 'sim-card',
            element: <SimCardSettings />,
            url: '/settings/sim-card',
            title: 'SIM Card',
            type: 'item',
          },
          {
            path: 'retail-plans',
            element: <RetailPlans />,
            url: '/settings/retail-plans',
            title: 'Retail Plans',
            type: 'item',
          },
          {
            path: 'retail-plans/create',
            element: <CreateOrEditRetailPlan />,
            url: '/settings/retail-plans/create',
            title: 'Create Retail Plan',
            type: 'item',
          },
          {
            path: 'retail-plans/edit/:id',
            element: <CreateOrEditRetailPlan />,
            url: '/settings/retail-plans/edit',
            title: 'Edit Retail Plan',
            type: 'item',
          },
          {
            path: 'carrier-settings',
            element: <CarrierSettings />,
            url: '/settings/carrier-settings',
            title: 'Carrier Settings',
            type: 'item',
          },
          {
            path: 'carrier/:id',
            element: <Carrier />,
            url: '/settings/carrier',
            title: 'Carriers Settings',
            type: 'item',
          },
          {
            path: 'carrier/:carrierId/new-wholesale-plan/add-new-plan',
            element: <CreateEditRetailPlan />,
            url: '/settings/carrier/:carrierId/new-wholesale-plan/add-new-plan',
            title: 'Create Wholesale Plan',
            type: 'item',
          },
          {
            path: 'carrier/:carrierId/wholesale-plan/edit-plan/:id',
            element: <CreateEditRetailPlan />,
            url: '/settings/carrier/:carrierId/wholesale-plan/edit-plan/:id',
            title: 'Edit WholeSale Plan',
            type: 'item',
          },
          {
            path: 'shipping',
            element: <CarrierSettings />,
            url: '/settings/shipping',
            title: 'Shipping',
            type: 'item',
          },
          {
            path: 'shipping/:id',
            element: <Shipping />,
            url: '/settings/shipping',
            title: 'Shipping Carriers',
            type: 'item',
          },
          {
            path: 'site-settings',
            element: <SiteSettings />,
            url: '/settings/site-settings',
            title: 'Site Settings',
            type: 'item',
          },
          {
            path: 'edit-main-banner',
            element: <EditMainBanner />,
            url: '/settings/edit-main-banner',
            title: 'Edit Main Banner',
            type: 'item',
          },
          {
            path: 'top-up/create',
            element: <CreateOrEditTopUp />,
            url: '/settings/top-up/create',
            title: 'Create Top-Up',
            type: 'item',
          },
          {
            path: 'top-up/edit/:id',
            element: <CreateOrEditTopUp />,
            url: '/settings/top-up/edit',
            title: 'Edit Top-Up',
            type: 'item',
          },
          {
            path: 'email-marketing',
            element: <EmailMarketing />,
            url: '/settings/email-marketing',
            title: 'Email Marketing',
            type: 'item',
          },
        ],
      },
      {
        path: 'e-commerce',
        resource: ['tenant'],
        type: 'collapse',
        breadcrumbs: true,
        url: '/e-commerce',
        title: 'E-commerce',
        children: [
          {
            path: 'sim-cards',
            element: <SIMCards />,
            url: '/e-commerce/sim-cards',
            title: 'SIM Card List',
            type: 'item',
          },
          {
            path: 'vendors',
            element: <Vendors />,
            url: '/e-commerce/vendors',
            title: 'Vendors List',
            type: 'item',
          },
          {
            path: 'categories',
            element: <Categories />,
            url: '/e-commerce/categories',
            title: 'Categories List',
            type: 'item',
          },
          {
            path: 'manufacturers',
            element: <Manufacturers />,
            url: '/e-commerce/manufacturers',
            title: 'Manufacturers List',
            type: 'item',
          },
          {
            path: 'devices',
            element: <Devices />,
            url: '/e-commerce/devices',
            title: 'Devices List',
            type: 'item',
          },
          {
            path: 'device/create',
            element: <DevicesCreate />,
            url: '/e-commerce/device/create',
            title: 'Create Device',
            type: 'item',
          },
          {
            path: 'device/edit/:id',
            element: <DeviceEdit />,
            url: '/e-commerce/device/edit',
            title: 'Edit Device',
            type: 'item',
          },
          {
            path: 'accessories',
            element: <Accessories />,
            url: '/e-commerce/accessories',
            title: 'Accessories List',
            type: 'item',
          },
          {
            path: 'accessories/create',
            element: <AccessoryCreate />,
            url: '/e-commerce/accessories/create',
            title: 'Create Accessory',
            type: 'item',
          },
          {
            path: 'accessories/edit/:id',
            element: <AccessoryEdit />,
            url: '/e-commerce/accessories/edit',
            title: 'Edit Accessory',
            type: 'item',
          },
          {
            path: 'inventory/:id',
            element: <Inventory />, //TODO: please add id values in conditionalLocationLastItems from index page
            url: '/e-commerce/inventory',
            title: 'Inventory',
            type: 'item',
          },
          {
            path: 'inventory/variant/:id',
            element: <InventoryVariantEdit />,
            url: '/e-commerce/inventory/variant',
            title: 'Variants Inventory',
            type: 'item',
          },
          {
            path: 'orders/agent-order',
            element: <AgentOrders />,
            url: '/e-commerce/orders/agent-order',
            title: 'Agent Orders',
            type: 'item',
          },
          {
            path: 'orders/agent-order/shipment/:id',
            element: <Shipment />,
            url: '/e-commerce/orders/agent-order/shipment',
            title: 'Shipment',
            type: 'item',
          },
          {
            path: 'orders/customer-order',
            element: <CustomerOrders />,
            url: '/e-commerce/orders/customer-order',
            title: 'Customer Orders',
            type: 'item',
          },
          {
            path: 'inventory/inventory-requests',
            element: <AgentOrders />,
            url: '/e-commerce/inventory/inventory-requests',
            title: 'Inventory Requests',
            type: 'item',
          },
          {
            path: 'orders/customer-order/fulfill-order/:id',
            element: <CustomerOrderFulfill />,
            url: '/e-commerce/orders/customer-order/fulfill-order',
            title: 'Fulfill Order',
            type: 'item',
          },
          {
            path: 'orders/customer-order/fulfill-shopify-order/:id',
            element: <CustomerShopifyOrderFulfill />,
            url: '/e-commerce/orders/customer-order/fulfill-shopify-order',
            title: 'Fulfill Shopify Order',
            type: 'item',
          },
          {
            path: 'promotions',
            element: <Promotions />,
            url: '/e-commerce/promotions',
            title: 'Promotions ',
            type: 'item',
          },
        ],
      },
      {
        path: 'billing',
        resource: ['tenant', 'all'],
        type: 'collapse',
        breadcrumbs: true,
        url: '/billing',
        title: 'Billing',
        children: [
          {
            path: 'payment-methods',
            element: <PaymentMethods />,
            url: '/billing/payment-methods',
            title: 'Payment Methods',
            type: 'item',
          },
          {
            path: 'payments-history',
            element: <PaymentsList />,
            url: '/billing/payments-history',
            title: 'Payment History',
            type: 'item',
          },
          {
            path: 'subscriptions',
            element: <Subscriptions />,
            url: '/billing/subscriptions',
            title: 'Subscriptions',
            type: 'item',
          },
          {
            path: 'subscribe-checkout',
            element: <EmbeddedForm />,
            url: '/billing/subscribe-checkout',
            title: 'Subscribe Checkout',
            type: 'item',
          },
          {
            path: 'subscription-details/:subscriptionId',
            element: <SubscriptionDetails />,
            url: '/billing/subscription-details',
            title: 'Subscription Details',
            type: 'item',
          },
        ],
      },
      {
        path: 'portal',
        resource: ['all'],
        type: 'collapse',
        breadcrumbs: true,
        url: '/portal',
        title: 'Portal',
        children: [
          {
            path: 'under-construction',
            element: <UnderConstruction />,
            url: '/portal/under-construction',
            title: 'Under Construction',
            type: 'item',
          },
        ],
      },
    ],
  },
];

export default MainRoutes;
