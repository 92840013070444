// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import auth from './auth/reducer';
import tenants from './admin/tenants/reducer';
import notifications from './notifications/reducer';
import customers from './customers/reducer';
import notes from './notes/reducer';
import agents from './agents/reducer';
import tickets from './tickets/reducer';
import snackbar from './snakbar/reducer';
import menu from './menu/reducer';
import kanban from './kanban/reducer';
import users from './users/reducer';
import acpSettings from './settings/acpSettings/reducer';
import simCardSettings from './settings/simCardSettings/reducer';
import simCards from './eCommerce/simCards/reducer';
import vendors from './eCommerce/vendors/reducer';
import categories from './eCommerce/categories/reducer';
import manufacturers from './eCommerce/manufacturers/reducer';
import products from './eCommerce/products/reducer';
import smtp from './settings/smtp/reducer';
import aps from './settings/aps/reducer';
import wsPlan from './settings/wsPlan/reducer';
import inventory from './eCommerce/inventory/reducer';
import retailPlan from './settings/retailPlan/reducer';
import orders from './eCommerce/orders/reducer';
import paymentMethods from './billing/paymentMethods/reducer';
import carriers from './carriers/reducer';
import shop from './eCommerce/shop/reducer';
import siteSettings from './settings/siteSettings/reducer';
import subscriptions from './billing/subscriptions/reducer';
import profitLossReports from './billing/profitLossReports/reducer';
import mails from './mails/reducer';
import promotions from './eCommerce/promotions/reducer';
import dashboard from './dashboard/reducer';
import globalSearch from './globalSearch/reducer';
import phoneNumbers from './admin/phoneNumbers/reducer';
import adminCarriers from './admin/carriers/reducer';
import customerTags from './settings/customerTags/reducer';

const rootReducer = combineReducers({
  auth,
  tenants,
  notifications,
  customers,
  notes,
  agents,
  tickets,
  snackbar,
  menu,
  kanban,
  users,
  acpSettings,
  simCardSettings,
  simCards,
  vendors,
  categories,
  manufacturers,
  products,
  smtp,
  aps,
  wsPlan,
  inventory,
  retailPlan,
  orders,
  paymentMethods,
  carriers,
  shop,
  siteSettings,
  subscriptions,
  mails,
  promotions,
  dashboard,
  globalSearch,
  phoneNumbers,
  profitLossReports,
  adminCarriers,
  customerTags,
});

export default rootReducer;
