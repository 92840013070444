import { axiosApiInstance, config } from 'custom-configs';

import {
  createCiscoAPSFailure,
  createCiscoAPSRequest,
  createCiscoAPSSuccess,
  deleteCiscoAPSFailure,
  deleteCiscoAPSRequest,
  deleteCiscoAPSSuccess,
  getAPSDataFailure,
  getAPSDataRequest,
  getAPSDataSuccess,
  getTenantsSelectOptionsFailure,
  getTenantsSelectOptionsRequest,
  getTenantsSelectOptionsSuccess,
  updateCiscoAPSFailure,
  updateCiscoAPSRequest,
  updateCiscoAPSSuccess,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';
import { catchResponseMessages } from 'utils/methods';

const URL = `${config.DOMAIN}/carriers`;

function* createAPS({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-aps`, payload)
    );
    if (response?.status === 200) {
      yield put(createCiscoAPSSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for createAPS, error', e);
    if (e?.response?.data) {
      yield put(createCiscoAPSFailure(catchResponseMessages(e)));
    }
  }
}

function* getAPSData({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/aps`, payload)
    );
    if (response?.status === 200) {
      yield put(getAPSDataSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getAPSData, error', e);
    if (e?.response?.data) {
      yield put(getAPSDataFailure(catchResponseMessages(e)));
    }
  }
}

function* tenantSelectOptions() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/tenant-options`)
    );
    if (response?.status === 200) {
      yield put(getTenantsSelectOptionsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for tenantSelectOptions, error', e);
    if (e?.response?.data) {
      yield put(getTenantsSelectOptionsFailure(catchResponseMessages(e)));
    }
  }
}

function* updateAPS({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/aps/${payload.id}`, payload.data)
    );
    if (response?.status === 200) {
      yield put(updateCiscoAPSSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateAPS, error', e);
    if (e?.response?.data) {
      yield put(updateCiscoAPSFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteAPS({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/aps/${payload}`)
    );
    if (response?.status === 200) {
      yield put(deleteCiscoAPSSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for deleteAPS, error', e);
    if (e?.response?.data) {
      yield put(deleteCiscoAPSFailure(catchResponseMessages(e)));
    }
  }
}
export default function* () {
  yield takeLatest(createCiscoAPSRequest, createAPS);
  yield takeLatest(getAPSDataRequest, getAPSData);
  yield takeLatest(getTenantsSelectOptionsRequest, tenantSelectOptions);
  yield takeLatest(updateCiscoAPSRequest, updateAPS);
  yield takeLatest(deleteCiscoAPSRequest, deleteAPS);
}
