import { handleActions } from 'redux-actions';
import {
  addTagFailure,
  addTagRequest,
  addTagSuccess,
  assignTagsToCustomerFailure,
  assignTagsToCustomerRequest,
  assignTagsToCustomerSuccess,
  deleteTagFailure,
  deleteTagRequest,
  deleteTagSuccess,
  getCustomersWithTagsFailure,
  getCustomersWithTagsRequest,
  getCustomersWithTagsSuccess,
  getCustomerTagsFailure,
  getCustomerTagsRequest,
  getCustomerTagsSuccess,
  updateTagFailure,
  updateTagRequest,
  updateTagSuccess,
} from './actions';

const initialState = {
  isGettingCustomerTagsRequest: false,
  isGettingCustomerTagsSuccess: false,
  isGettingCustomerTagsFailure: false,
  errorMessage: '',
  successMessage: '',
  customerTags: [],
  isAddingTagRequest: false,
  isAddingTagSuccess: false,
  isAddingTagFailure: false,
  isDeletingTagRequest: false,
  isDeletingTagSuccess: false,
  isDeletingTagFailure: false,
  isUpdatingTagRequest: false,
  isUpdatingTagSuccess: false,
  isUpdatingTagFailure: false,
  customers: [],
  isGettingCustomersWithTagsRequest: false,
  isGettingCustomersWithTagsSuccess: false,
  isGettingCustomersWithTagsFailure: false,
  isAssigningTagsToCustomerRequest: false,
  isAssigningTagsToCustomerSuccess: false,
  isAssigningTagsToCustomerFailure: false,
};

const reducer = handleActions(
  {
    [getCustomerTagsRequest]: (state) => ({
      ...state,
      isGettingCustomerTagsRequest: true,
    }),
    [getCustomerTagsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCustomerTagsRequest: false,
      isGettingCustomerTagsSuccess: true,
      successMessage: payload.message,
      customerTags: payload.data,
    }),
    [getCustomerTagsFailure]: (state, { payload }) => ({
      ...state,
      isGettingCustomerTagsRequest: false,
      isGettingCustomerTagsFailure: true,
      errorMessage: payload,
    }),
    [addTagRequest]: (state) => ({
      ...state,
      isAddingTagRequest: true,
      isAddingTagSuccess: false,
      isAddingTagFailure: false,
    }),
    [addTagSuccess]: (state, { payload }) => ({
      ...state,
      isAddingTagRequest: false,
      isAddingTagSuccess: true,
      successMessage: payload.message,
      customerTags: {
        rows: [...state.customerTags.rows, payload.data],
        count: ++state.customerTags.count,
      },
    }),
    [addTagFailure]: (state, { payload }) => ({
      ...state,
      isAddingTagRequest: false,
      isAddingTagFailure: true,
      errorMessage: payload,
    }),
    [deleteTagRequest]: (state) => ({
      ...state,
      isDeletingTagRequest: true,
      isDeletingTagSuccess: false,
      isDeletingTagFailure: false,
    }),
    [deleteTagSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingTagRequest: false,
      isDeletingTagSuccess: true,
      successMessage: payload.message,
      customerTags: {
        rows: state.customerTags.rows.filter((tag) => tag.id !== payload.data),
        count: --state.customerTags.count,
      },
    }),
    [deleteTagFailure]: (state, { payload }) => ({
      ...state,
      isDeletingTagRequest: false,
      isDeletingTagFailure: true,
      errorMessage: payload,
    }),
    [updateTagRequest]: (state) => ({
      ...state,
      isUpdatingTagRequest: true,
      isUpdatingTagSuccess: false,
      isUpdatingTagFailure: false,
    }),
    [updateTagSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingTagRequest: false,
      isUpdatingTagSuccess: true,
      successMessage: payload.message,
      customerTags: {
        ...state.customerTags,
        rows: state.customerTags.rows.map((tag) =>
          tag.id === payload.data.id ? payload.data : tag
        ),
      },
    }),
    [updateTagFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingTagRequest: false,
      isUpdatingTagFailure: true,
      errorMessage: payload,
    }),
    [getCustomersWithTagsRequest]: (state) => ({
      ...state,
      isGettingCustomersWithTagsRequest: true,
    }),
    [getCustomersWithTagsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCustomersWithTagsRequest: false,
      isGettingCustomersWithTagsSuccess: true,
      successMessage: payload.message,
      customers: payload.data,
    }),
    [getCustomersWithTagsFailure]: (state, { payload }) => ({
      ...state,
      isGettingCustomersWithTagsRequest: false,
      isGettingCustomersWithTagsFailure: true,
      errorMessage: payload,
    }),
    [assignTagsToCustomerRequest]: (state) => ({
      ...state,
      isAssigningTagsToCustomerRequest: true,
      isAssigningTagsToCustomerSuccess: false,
      isAssigningTagsToCustomerFailure: false,
    }),
    [assignTagsToCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isAssigningTagsToCustomerRequest: false,
      isAssigningTagsToCustomerSuccess: true,
      successMessage: payload.message,
      customers: {
        ...state.customers,
        rows: state.customers.rows.map((customer) =>
          payload.data.id === customer.id ? payload.data : customer
        ),
      },
    }),
    [assignTagsToCustomerFailure]: (state, { payload }) => ({
      ...state,
      isAssigningTagsToCustomerRequest: false,
      isAssigningTagsToCustomerFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
