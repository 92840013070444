import { createAction } from 'redux-actions';

export const createCiscoAPSRequest = createAction('CREATE_CISCO_APS_REQUEST');
export const createCiscoAPSSuccess = createAction('CREATE_CISCO_APS_SUCCESS');
export const createCiscoAPSFailure = createAction('CREATE_CISCO_APS_FAILURE');

export const getAPSDataRequest = createAction('GET_APS_DATA_REQUEST');
export const getAPSDataSuccess = createAction('GET_APS_DATA_SUCCESS');
export const getAPSDataFailure = createAction('GET_APS_DATA_FAILURE');

export const getTenantsSelectOptionsRequest = createAction(
  'GET_TENANTS_SELECT_OPTIONS_REQUEST'
);
export const getTenantsSelectOptionsSuccess = createAction(
  'GET_TENANTS_SELECT_OPTIONS_SUCCESS'
);
export const getTenantsSelectOptionsFailure = createAction(
  'GET_TENANTS_SELECT_OPTIONS_FAILURE'
);

export const updateCiscoAPSRequest = createAction('UPDATE_CISCO_APS_REQUEST');
export const updateCiscoAPSSuccess = createAction('UPDATE_CISCO_APS_SUCCESS');
export const updateCiscoAPSFailure = createAction('UPDATE_CISCO_APS_FAILURE');

export const deleteCiscoAPSRequest = createAction('DELETE_CISCO_APS_REQUEST');
export const deleteCiscoAPSSuccess = createAction('DELETE_CISCO_APS_SUCCESS');
export const deleteCiscoAPSFailure = createAction('DELETE_CISCO_APS_FAILURE');
