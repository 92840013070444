import { createAction } from 'redux-actions';

export const getInventoryRequestListRequest = createAction(
  'GET_INVENTORY_REQUEST_LIST_REQUEST'
);
export const getInventoryRequestListSuccess = createAction(
  'GET_INVENTORY_REQUEST_LIST_SUCCESS'
);
export const getInventoryRequestListFailure = createAction(
  'GET_INVENTORY_REQUEST_LIST_FAILURE'
);

export const rejectInventoryOrdersRequest = createAction(
  'REJECT_INVENTORY_ORDERS_REQUEST'
);
export const rejectInventoryOrdersSuccess = createAction(
  'REJECT_INVENTORY_ORDERS_SUCCESS'
);
export const rejectInventoryOrdersFailure = createAction(
  'REJECT_INVENTORY_ORDERS_FAILURE'
);

export const fulfillOrderRequest = createAction('FULFILL_ORDER_REQUEST');
export const fulfillOrderSuccess = createAction('FULFILL_ORDER_SUCCESS');
export const fulfillOrderFailure = createAction('FULFILL_ORDER_FAILURE');

export const pushCreatedInventoryToOrdersList = createAction(
  'PUSH_CREATED_INVENTORY_TO_ORDERS_LIST'
);

export const cancelInventoryRequestRequest = createAction(
  'CANCEL_INVENTORY_REQUEST_REQUEST'
);
export const cancelInventoryRequestSuccess = createAction(
  'CANCEL_INVENTORY_REQUEST_SUCCESS'
);
export const cancelInventoryRequestFailure = createAction(
  'CANCEL_INVENTORY_REQUEST_FAILURE'
);

export const createAgentOrderRequest = createAction(
  'CREATE_AGENT_ORDER_REQUEST'
);
export const createAgentOrderSuccess = createAction(
  'CREATE_AGENT_ORDER_SUCCESS'
);
export const createAgentOrderFailure = createAction(
  'CREATE_AGENT_ORDER_FAILURE'
);

export const getCarrierServicesSelectRequest = createAction(
  'GET_CARRIER_SERVICES_SELECT_REQUEST'
);
export const getCarrierServicesSelectSuccess = createAction(
  'GET_CARRIER_SERVICES_SELECT_SUCCESS'
);
export const getCarrierServicesSelectFailure = createAction(
  'GET_CARRIER_SERVICES_SELECT_FAILURE'
);

export const getOrderHistoryRequest = createAction('GET_ORDER_HISTORY_REQUEST');
export const getOrderHistorySuccess = createAction('GET_ORDER_HISTORY_SUCCESS');
export const getOrderHistoryFailure = createAction('GET_ORDER_HISTORY_FAILURE');

export const checkingRatesRequest = createAction('CHECKING_RATES_REQUEST');
export const checkingRatesSuccess = createAction('CHECKING_RATES_SUCCESS');
export const checkingRatesFailure = createAction('CHECKING_RATES_FAILURE');

export const trackShippingRequest = createAction('TRACK_SHIPPING_REQUEST');
export const trackShippingSuccess = createAction('TRACK_SHIPPING_SUCCESS');
export const trackShippingFailure = createAction('TRACK_SHIPPING_FAILURE');

export const getInventoryRequestRequest = createAction(
  'GET_INVENTORY_REQUEST_REQUEST'
);
export const getInventoryRequestSuccess = createAction(
  'GET_INVENTORY_REQUEST_SUCCESS'
);
export const getInventoryRequestFailure = createAction(
  'GET_INVENTORY_REQUEST_FAILURE'
);

export const getCustomerOrdersRequest = createAction(
  'GET_CUSTOMER_ORDERS_REQUEST'
);

export const getCustomerOrdersSuccess = createAction(
  'GET_CUSTOMER_ORDERS_SUCCESS'
);

export const getCustomerOrdersFailure = createAction(
  'GET_CUSTOMER_ORDERS_FAILURE'
);

export const findCustomerOrderRequest = createAction(
  'FIND_CUSTOMER_ORDER_REQUEST'
);
export const findCustomerOrderSuccess = createAction(
  'FIND_CUSTOMER_ORDER_SUCCESS'
);
export const findCustomerOrderFailure = createAction(
  'FIND_CUSTOMER_ORDER_FAILURE'
);

export const updateCustomerShippingAddressRequest = createAction(
  'UPDATE_CUSTOMER_SHIPPING_ADDRESS_REQUEST'
);
export const updateCustomerShippingAddressSuccess = createAction(
  'UPDATE_CUSTOMER_SHIPPING_ADDRESS_SUCCESS'
);
export const updateCustomerShippingAddressFailure = createAction(
  'UPDATE_CUSTOMER_SHIPPING_ADDRESS_FAILURE'
);

export const clearRequestData = createAction('CLEAR_REQUEST_DATA');

export const estimateShippingRateRequest = createAction(
  'ESTIMATE_SHIPPING_RATE_REQUEST'
);
export const estimateShippingRateSuccess = createAction(
  'ESTIMATE_SHIPPING_RATE_SUCCESS'
);
export const estimateShippingRateFailure = createAction(
  'ESTIMATE_SHIPPING_RATE_FAILURE'
);

export const fulfillCustomerOrderRequest = createAction(
  'FULFILL_CUSTOMER_ORDER_REQUEST'
);
export const fulfillCustomerOrderSuccess = createAction(
  'FULFILL_CUSTOMER_ORDER_SUCCESS'
);
export const fulfillCustomerOrderFailure = createAction(
  'FULFILL_CUSTOMER_ORDER_FAILURE'
);

export const fulfillHandoverOrderRequest = createAction(
  'FULFILL_HANDOVER_ORDER_REQUEST'
);
export const fulfillHandoverOrderSuccess = createAction(
  'FULFILL_HANDOVER_ORDER_SUCCESS'
);
export const fulfillHandoverOrderFailure = createAction(
  'FULFILL_HANDOVER_ORDER_FAILURE'
);

export const updateCustomerOrderLine = createAction(
  'UPDATE_CUSTOMER_ORDER_LINE'
);

export const activateOtherOrderIMEIRequest = createAction(
  'ACTIVATE_OTHER_ORDER_IMEI_REQUEST'
);
export const activateOtherOrderIMEISuccess = createAction(
  'ACTIVATE_OTHER_ORDER_IMEI_SUCCESS'
);
export const activateOtherOrderIMEIFailure = createAction(
  'ACTIVATE_OTHER_ORDER_IMEI_FAILURE'
);

export const shipCustomerOrderRequest = createAction(
  'SHIP_CUSTOMER_ORDER_REQUEST'
);
export const shipCustomerOrderSuccess = createAction(
  'SHIP_CUSTOMER_ORDER_SUCCESS'
);
export const shipCustomerOrderFailure = createAction(
  'SHIP_CUSTOMER_ORDER_FAILURE'
);

export const getSimCardWithIMEIRequest = createAction(
  'GET_SIM_CARD_WITH_IMEI_REQUEST'
);
export const getSimCardWithIMEISuccess = createAction(
  'GET_SIM_CARD_WITH_IMEI_SUCCESS'
);
export const getSimCardWithIMEIFailure = createAction(
  'GET_SIM_CARD_WITH_IMEI_FAILURE'
);

export const finishCustomerOrderRequest = createAction(
  'FINISH_CUSTOMER_ORDER_REQUEST'
);
export const finishCustomerOrderSuccess = createAction(
  'FINISH_CUSTOMER_ORDER_SUCCESS'
);
export const finishCustomerOrderFailure = createAction(
  'FINISH_CUSTOMER_ORDER_FAILURE'
);

export const handoverAndFulfillRequest = createAction(
  'HANDOVER_AND_FULFILL_REQUEST'
);
export const handoverAndFulfillSuccess = createAction(
  'HANDOVER_AND_FULFILL_SUCCESS'
);
export const handoverAndFulfillFailure = createAction(
  'HANDOVER_AND_FULFILL_FAILURE'
);

export const handoverQuicklyRequest = createAction('HANDOVER_QUICKLY_REQUEST');
export const handoverQuicklySuccess = createAction('HANDOVER_QUICKLY_SUCCESS');
export const handoverQuicklyFailure = createAction('HANDOVER_QUICKLY_FAILURE');

export const getOrderLineWithDataRequest = createAction(
  'GET_ORDER_LINE_WITH_DATA_REQUEST'
);
export const getOrderLineWithDataSuccess = createAction(
  'GET_ORDER_LINE_WITH_DATA_SUCCESS'
);
export const getOrderLineWithDataFailure = createAction(
  'GET_ORDER_LINE_WITH_DATA_FAILURE'
);

export const updateShippingRateRequest = createAction(
  'UPDATE_SHIPPING_RATE_REQUEST'
);
export const updateShippingRateSuccess = createAction(
  'UPDATE_SHIPPING_RATE_SUCCESS'
);
export const updateShippingRateFailure = createAction(
  'UPDATE_SHIPPING_RATE_FAILURE'
);

export const resubmitOrderRequest = createAction('RESUBMIT_ORDER_REQUEST');
export const resubmitOrderSuccess = createAction('RESUBMIT_ORDER_SUCCESS');
export const resubmitOrderFailure = createAction('RESUBMIT_ORDER_FAILURE');

export const getInvoicesForLineRefundRequest = createAction(
  'GET_INVOICES_FOR_LINE_REFUND_REQUEST'
);
export const getInvoicesForLineRefundSuccess = createAction(
  'GET_INVOICES_FOR_LINE_REFUND_SUCCESS'
);
export const getInvoicesForLineRefundFailure = createAction(
  'GET_INVOICES_FOR_LINE_REFUND_FAILURE'
);

export const refundLineAmountRequest = createAction(
  'REFUND_LINE_AMOUNT_REQUEST'
);
export const refundLineAmountSuccess = createAction(
  'REFUND_LINE_AMOUNT_SUCCESS'
);
export const refundLineAmountFailure = createAction(
  'REFUND_LINE_AMOUNT_FAILURE'
);

export const updateOrderRequest = createAction('UPDATE_ORDER_REQUEST');
export const updateOrderSuccess = createAction('UPDATE_ORDER_SUCCESS');
export const updateOrderFailure = createAction('UPDATE_ORDER_FAILURE');

export const deactivateLineRequest = createAction('DEACTIVATE_LINE_REQUEST');
export const deactivateLineSuccess = createAction('DEACTIVATE_LINE_SUCCESS');
export const deactivateLineFailure = createAction('DEACTIVATE_LINE_FAILURE');

export const reactivateLineRequest = createAction('REACTIVATE_LINE_REQUEST');
export const reactivateLineSuccess = createAction('REACTIVATE_LINE_SUCCESS');
export const reactivateLineFailure = createAction('REACTIVATE_LINE_FAILURE');

export const removeLinePortRequest = createAction('REMOVE_LINE_PORT_REQUEST');
export const removeLinePortSuccess = createAction('REMOVE_LINE_PORT_SUCCESS');
export const removeLinePortFailure = createAction('REMOVE_LINE_PORT_FAILURE');

export const keepOldNumberRequest = createAction('KEEP_OLD_NUMBER_REQUEST');
export const keepOldNumberSuccess = createAction('KEEP_OLD_NUMBER_SUCCESS');
export const keepOldNumberFailure = createAction('KEEP_OLD_NUMBER_FAILURE');

export const clearRefundData = createAction('CLEAR_REFUND_DATA');

export const getHotlinesRequest = createAction('GET_HOTLINES_REQUEST');
export const getHotlinesSuccess = createAction('GET_HOTLINES_SUCCESS');
export const getHotlinesFailure = createAction('GET_HOTLINES_FAILURE');

export const getDeactivatedLinesRequest = createAction(
  'GET_DEACTIVATED_LINES_REQUEST'
);
export const getDeactivatedLinesSuccess = createAction(
  'GET_DEACTIVATED_LINES_SUCCESS'
);
export const getDeactivatedLinesFailure = createAction(
  'GET_DEACTIVATED_LINES_FAILURE'
);

export const removeLineRequest = createAction('REMOVE_LINE_REQUEST');
export const removeLineSuccess = createAction('REMOVE_LINE_SUCCESS');
export const removeLineFailure = createAction('REMOVE_LINE_FAILURE');

export const recreateBillingRequest = createAction('RECREATE_BILLING_REQUEST');
export const recreateBillingSuccess = createAction('RECREATE_BILLING_SUCCESS');
export const recreateBillingFailure = createAction('RECREATE_BILLING_FAILURE');

export const createCombinedBillingRequest = createAction(
  'CREATE_COMBINED_BILLING_REQUEST'
);
export const createCombinedBillingSuccess = createAction(
  'CREATE_COMBINED_BILLING_SUCCESS'
);
export const createCombinedBillingFailure = createAction(
  'CREATE_COMBINED_BILLING_FAILURE'
);

export const getCanceledLinesRequest = createAction(
  'GET_CANCELED_LINES_REQUEST'
);
export const getCanceledLinesSuccess = createAction(
  'GET_CANCELED_LINES_SUCCESS'
);
export const getCanceledLinesFailure = createAction(
  'GET_CANCELED_LINES_FAILURE'
);

export const abandonedAndFailedOrdersRequest = createAction(
  'ABANDONED_AND_FAILED_ORDERS_REQUEST'
);
export const abandonedAndFailedOrdersSuccess = createAction(
  'ABANDONED_AND_FAILED_ORDERS_SUCCESS'
);
export const abandonedAndFailedOrdersFailure = createAction(
  'ABANDONED_AND_FAILED_ORDERS_FAILURE'
);

export const activeLinesWithNoSubscriptionRequest = createAction(
  'ACTIVE_LINES_WITH_NO_SUBSCRIPTION_REQUEST'
);
export const activeLinesWithNoSubscriptionSuccess = createAction(
  'ACTIVE_LINES_WITH_NO_SUBSCRIPTION_SUCCESS'
);
export const activeLinesWithNoSubscriptionFailure = createAction(
  'ACTIVE_LINES_WITH_NO_SUBSCRIPTION_FAILURE'
);

export const getCustomerOrdersRequestById = createAction(
  'GET_CUSTOMER_ORDERS_BY_ID_REQUEST'
);
export const getCustomerOrdersByIdSuccess = createAction(
  'GET_CUSTOMER_ORDERS_BY_ID_SUCCESS'
);
export const getCustomerOrdersByIdFailure = createAction(
  'GET_CUSTOMER_ORDERS_BY_ID_FAILURE'
);

export const getDuplicatedCustomerAccountsRequest = createAction(
  'GET_DUPLICATED_CUSTOMER_ACCOUNTS_REQUEST'
);
export const getDuplicatedCustomerAccountsSuccess = createAction(
  'GET_DUPLICATED_CUSTOMER_ACCOUNTS_SUCCESS'
);
export const getDuplicatedCustomerAccountsFailure = createAction(
  'GET_DUPLICATED_CUSTOMER_ACCOUNTS_FAILURE'
);

export const clearDuplicatedCustomersAccounts = createAction(
  'CLEAR_DUPLICATED_CUSTOMERS_ACCOUNTS'
);

export const changeShopifyLinePlanRequest = createAction(
  'CHANGE_SHOPIFY_LINE_PLAN_REQUEST'
);
export const changeShopifyLinePlanSuccess = createAction(
  'CHANGE_SHOPIFY_LINE_PLAN_SUCCESS'
);
export const changeShopifyLinePlanFailure = createAction(
  'CHANGE_SHOPIFY_LINE_PLAN_FAILURE'
);

export const changeShopifyOrderVariantRequest = createAction(
  'CHANGE_SHOPIFY_ORDER_VARIANT_REQUEST'
);
export const changeShopifyOrderVariantSuccess = createAction(
  'CHANGE_SHOPIFY_ORDER_VARIANT_SUCCESS'
);
export const changeShopifyOrderVariantFailure = createAction(
  'CHANGE_SHOPIFY_ORDER_VARIANT_FAILURE'
);

export const activateShopifyOtherLineRequest = createAction(
  'ACTIVATE_SHOPIFY_OTHER_LINE_REQUEST'
);
export const activateShopifyOtherLineSuccess = createAction(
  'ACTIVATE_SHOPIFY_OTHER_LINE_SUCCESS'
);
export const activateShopifyOtherLineFailure = createAction(
  'ACTIVATE_SHOPIFY_OTHER_LINE_FAILURE'
);

export const fulfillShopifyOrderRequest = createAction(
  'FULFILL_SHOPIFY_ORDER_REQUEST'
);
export const fulfillShopifyOrderSuccess = createAction(
  'FULFILL_SHOPIFY_ORDER_SUCCESS'
);
export const fulfillShopifyOrderFailure = createAction(
  'FULFILL_SHOPIFY_ORDER_FAILURE'
);

export const updateSimTypeRequest = createAction('UPDATE_SIM_TYPE_REQUEST');
export const updateSimTypeSuccess = createAction('UPDATE_SIM_TYPE_SUCCESS');
export const updateSimTypeFailure = createAction('UPDATE_SIM_TYPE_FAILURE');
