import { axiosApiInstance, config } from '../../../custom-configs';
import { catchResponseMessages } from 'utils/methods';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  getCustomerTagsSuccess,
  getCustomerTagsFailure,
  getCustomerTagsRequest,
  addTagSuccess,
  addTagFailure,
  addTagRequest,
  deleteTagSuccess,
  deleteTagFailure,
  deleteTagRequest,
  updateTagSuccess,
  updateTagFailure,
  updateTagRequest,
  getCustomersWithTagsRequest,
  getCustomersWithTagsFailure,
  getCustomersWithTagsSuccess,
  assignTagsToCustomerRequest,
  assignTagsToCustomerSuccess,
  assignTagsToCustomerFailure,
} from './actions';

const URL = `${config.TENANT}/mailchimp`;

function* getCustomerTags({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/get-all-tags${payload}`)
    );
    if (response?.status === 200) {
      yield put(getCustomerTagsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getCustomerTags, error', e);
    if (e?.response?.data) {
      yield put(getCustomerTagsFailure(catchResponseMessages(e)));
    }
  }
}

function* addTag({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/create-tag`, payload)
    );
    if (response?.status === 200) {
      yield put(addTagSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for addTag, error', e);
    if (e?.response?.data) {
      yield put(addTagFailure(catchResponseMessages(e)));
    }
  }
}

function* updateTag({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(`${URL}/update-tag/${payload.id}`, payload.data)
    );
    if (response?.status === 200) {
      yield put(updateTagSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for updateTag, error', e);
    if (e?.response?.data) {
      yield put(updateTagFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteTag({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/delete-tag/${payload.id}`)
    );
    if (response?.status === 200) {
      yield put(deleteTagSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for addTag, error', e);
    if (e?.response?.data) {
      yield put(deleteTagFailure(catchResponseMessages(e)));
    }
  }
}

function* getCustomersWithTags({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/customers${payload}`)
    );
    if (response?.status === 200) {
      yield put(getCustomersWithTagsSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for getCustomerTags, error', e);
    if (e?.response?.data) {
      yield put(getCustomersWithTagsFailure(catchResponseMessages(e)));
    }
  }
}

function* assignTagsToCustomer({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/assign-tag`, payload)
    );
    if (response?.status === 200) {
      yield put(assignTagsToCustomerSuccess(response.data));
    }
  } catch (e) {
    console.log('Catch for assignTagsToCustomer, error', e);
    if (e?.response?.data) {
      yield put(assignTagsToCustomerFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(getCustomerTagsRequest, getCustomerTags);
  yield takeLatest(addTagRequest, addTag);
  yield takeLatest(deleteTagRequest, deleteTag);
  yield takeLatest(updateTagRequest, updateTag);
  yield takeLatest(getCustomersWithTagsRequest, getCustomersWithTags);
  yield takeLatest(assignTagsToCustomerRequest, assignTagsToCustomer);
}
