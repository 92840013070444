import Swal from 'sweetalert2';
import moment from 'moment/moment';
import 'sweetalert2/src/sweetalert2.scss';

import { City, State } from 'country-state-city';
import { openSnackbar } from 'redux/snakbar/reducer';
import { globalSearchClear } from '../redux/globalSearch/actions';
import { dispatch } from 'redux/store';
import masterCardLogo from '../assets/logos/mastercard.png';
import dinersClubLogo from '../assets/logos/dinersClub.png';
import discoverLogo from '../assets/logos/discover.png';
import unionPayLogo from '../assets/logos/unionPay.png';
import visaLogo from '../assets/logos/visa.png';
import amexLogo from '../assets/logos/amex.png';
import jcbLogo from '../assets/logos/jcb.png';
import { v4 as UIDV4 } from 'uuid';

import * as Yup from 'yup';
import { backgroundImageBase64 } from './backgroundImageInBase64';

export const isArray = (arr) => {
  try {
    return !!Array.isArray(arr);
  } catch (e) {
    return false;
  }
};

export const showMessages = (message, type = 'error') => {
  return dispatch(
    openSnackbar({
      open: true,
      transition: 'SlideLeft',
      message: isArray(message) ? message.join('') : message,
      variant: 'alert',
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      alert: {
        color: type,
      },
    })
  );
};

export const removeEmptyItemsFromObject = (obj) => {
  if (typeof obj !== 'object') {
    return obj;
  }
  for (const [field, value] of Object.entries(obj)) {
    if (value !== false && value !== 0 && !value) {
      delete obj[field];
    } else if (Array.isArray(value) && !value.length) {
      delete obj[field];
    } else if (field === 'confirmPassword') {
      delete obj[field];
    }
    if (typeof obj[field] === 'object') {
      removeEmptyItemsFromObject(obj[field]);
    }
  }

  return obj;
};

export const makeSearchQuery = (queryObject) => {
  const query = new URLSearchParams(
    removeEmptyItemsFromObject(queryObject)
  ).toString();
  return query ? `?${query}` : '';
};

export const confirmAlert = async (message) => {
  return Swal.fire({
    title: 'Please confirm!',
    text: message,
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    confirmButtonClass: 'cancel-button-custom',
    customClass: {
      cancelButton: 'cancel-button-custom',
      confirmButton: 'cancel-button-custom',
    },
  }).then((result) => {
    if (result.isConfirmed) {
      return true;
    } else if (result.isDismissed) {
      return false;
    }
  });
};

export const stateZipCode = [
  { title: 'AL', label: 'Alabama', sac: '' },
  { title: 'AK', label: 'Alaska', sac: '' },
  { title: 'AZ', label: 'Arizona', sac: '' },
  { title: 'AR', label: 'Arkansas', sac: '' },
  { title: 'CA', label: 'California', sac: '' },
  { title: 'CO', label: 'Colorado', sac: '' },
  { title: 'CT', label: 'Connecticut', sac: '' },
  { title: 'DE', label: 'Delaware', sac: '' },
  { title: 'FL', label: 'Florida', sac: '' },
  { title: 'GA', label: 'Georgia', sac: '' },
  { title: 'HI', label: 'Hawaii', sac: '' },
  { title: 'ID', label: 'Idaho', sac: '' },
  { title: 'IL', label: 'Illinois', sac: '' },
  { title: 'IN', label: 'Indiana', sac: '' },
  { title: 'IA', label: 'Iowa', sac: '' },
  { title: 'KS', label: 'Kansas', sac: '' },
  { title: 'KY', label: 'Kentucky', sac: '' },
  { title: 'LA', label: 'Louisiana', sac: '' },
  { title: 'ME', label: 'Maine', sac: '' },
  { title: 'MD', label: 'Maryland', sac: '' },
  { title: 'MA', label: 'Massachusetts', sac: '' },
  { title: 'MI', label: 'Michigan', sac: '' },
  { title: 'MN', label: 'Minnesota', sac: '' },
  { title: 'MS', label: 'Mississippi', sac: '' },
  { title: 'MO', label: 'Missouri', sac: '' },
  { title: 'MT', label: 'Montana', sac: '' },
  { title: 'NE', label: 'Nebraska', sac: '' },
  { title: 'NV', label: 'Nevada', sac: '' },
  { title: 'NH', label: 'New Hampshire', sac: '' },
  { title: 'NJ', label: 'New Jersey', sac: '' },
  { title: 'NM', label: 'New Mexico', sac: '' },
  { title: 'NY', label: 'New York', sac: '' },
  { title: 'NC', label: 'North Carolina', sac: '' },
  { title: 'ND', label: 'North Dakota', sac: '' },
  { title: 'OH', label: 'Ohio', sac: '' },
  { title: 'OK', label: 'Oklahoma', sac: '' },
  { title: 'OR', label: 'Oregon', sac: '' },
  { title: 'PA', label: 'Pennsylvania', sac: '' },
  { title: 'RI', label: 'Rhode Island', sac: '' },
  { title: 'SC', label: 'South Carolina', sac: '' },
  { title: 'SD', label: 'South Dakota', sac: '' },
  { title: 'TN', label: 'Tennessee', sac: '' },
  { title: 'TX', label: 'Texas', sac: '' },
  { title: 'UT', label: 'Utah', sac: '' },
  { title: 'VT', label: 'Vermont', sac: '' },
  { title: 'VA', label: 'Virginia', sac: '' },
  { title: 'WA', label: 'Washington', sac: '' },
  { title: 'WV', label: 'West Virginia', sac: '' },
  { title: 'WI', label: 'Wisconsin', sac: '' },
  { title: 'WY', label: 'Wyoming', sac: '' },
];

export const getStateOptions = (code) =>
  State.getStatesOfCountry(code)
    .map((item) => ({
      title: item?.isoCode,
      label: item?.name,
    }))
    .filter((i) => stateZipCode.map((s) => s.title).includes(i.title));

export const getStatesSelect = (code) =>
  State.getStatesOfCountry(code).map((item) => ({
    title: item?.isoCode,
    label: item?.name,
  }));

export const getStates = (states = []) => {
  return states
    .map((item) => ({
      title: item?.stateCode,
      label: item?.stateName,
    }))
    .filter((i) => stateZipCode.map((s) => s.title).includes(i.title));
};

export const getCityOptions = (countryCode, stateCode) =>
  City.getCitiesOfState(countryCode, stateCode).map((item) => item?.name);

export const getCitySelectOptions = (countryCode, stateCode) =>
  City.getCitiesOfState(countryCode, stateCode).map((item) => ({
    title: item?.name,
    label: item?.name,
  }));

export const toUpperFirstLetter = (str = '') => {
  return str[0]?.toUpperCase() + str?.slice(1);
};

export const isObjectHasLength = (obj) => {
  try {
    return !!Object.values(obj).length;
  } catch (e) {
    return false;
  }
};

export const phoneRegExp =
  /^[1-9+](1\s?)?(\d{3}|\(\d{3}\))[\s-]?\d{3}[\s-]?\d{4}$/gm;

export const publicHousingCodeList = [
  { title: '1', label: 'Housing Choice Voucher Program' },
  { title: '2', label: 'Project-based rental assistance' },
  { title: '3', label: 'Public housing' },
  {
    title: '4',
    label:
      'Affordable Housing Programs for American Indians, Alaska Natives or Native Hawaiians',
  },
];

export const contactList = [
  {
    label: 'Email',
    type: 'checkbox',
    name: 'feedbackIsEmail',
  },
  {
    label: 'Phone',
    type: 'checkbox',
    name: 'feedbackIsPhone',
  },
  {
    label: 'Text Message',
    type: 'checkbox',
    name: 'feedbackIsTextMessage',
  },
  {
    label: 'WhatsApp',
    type: 'checkbox',
    name: 'feedbackIsWhatsApp',
  },
  {
    label: 'Mail',
    type: 'checkbox',
    name: 'feedbackIsMail',
  },
];

export const contactListLabel = {
  feedbackIsEmail: 'Email',
  feedbackIsPhone: 'Phone',
  feedbackIsTextMessage: 'Text Message',
  feedbackIsWhatsApp: 'WhatsApp',
  feedbackIsMail: 'Mail',
};

export const getContactListLabels = (list) => {
  return list?.reduce((acc, item, index) => {
    acc += `${index ? ', ' : ''}${contactListLabel[item]}`;
    return acc;
  }, '');
};

export const programsList = [
  {
    program_code: 'MEDIC',
    program_type: 'Federal Programs',
    name: 'programCode_E1',
    label: 'Medicaid',
    type: 'checkbox',
    value: 'E1',
  },
  {
    program_code: 'SNAP',
    program_type: 'Federal Programs',
    name: 'programCode_E2',
    label: 'Supplemental Nutrition Assistance Program (SNAP) Food Stamps',
    type: 'checkbox',
    value: 'E2',
  },
  {
    program_code: 'SSI',
    program_type: 'Federal Programs',
    name: 'programCode_E3',
    label: 'Supplemental Security Income (SSI)',
    type: 'checkbox',
    value: 'E3',
  },
  {
    program_code: 'FPH',
    program_type: 'Federal Programs',
    name: 'programCode_E4',
    label: 'Federal Public Housing Assistance',
    type: 'checkbox',
    value: 'E4',
  },
  {
    label: 'Bureau of Indian Affairs General Assistance',
    type: 'checkbox',
    value: 'E8',
  },
  {
    label: 'Tribal Temporary Assistance for Needy Families (Tribal TANF)',
    type: 'checkbox',
    value: 'E9',
  },
  {
    label: 'Food Distribution Program on Indian Reservations (FDPIR)',
    type: 'checkbox',
    value: 'E10',
  },
  {
    label: 'Head Start',
    type: 'checkbox',
    value: 'E11',
  },
  {
    label: 'Apply based on income',
    type: 'checkbox',
    value: 'E13',
  },
  {
    program_code: 'VPSBP',
    program_type: 'Federal Programs',
    name: 'programCode_E15',
    label: 'Veterans Pension and Survivors Benefit Program',
    type: 'checkbox',
    value: 'E15',
  },
  {
    label: 'Federal Pell Grant',
    type: 'checkbox',
    value: 'E51',
  },
  {
    label:
      'Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)',
    type: 'checkbox',
    value: 'E54',
  },
  {
    label: 'FEMA’s Individuals and Households Program (IHP)',
    type: 'checkbox',
    value: 'E55',
  },
  {
    label: 'School Lunch/Breakfast Program',
    type: 'checkbox',
    isChild: true,
    value: 'E50',
  },
];

export const acpStatuses = [
  {
    name: 'ALL',
    label: 'All ACP Statuses',
  },
  {
    name: 'COMPLETE',
    label: 'Complete',
  },
  {
    name: 'IN_PROGRESS',
    label: 'In Progress',
  },
  {
    name: 'PENDING_RESOLUTION',
    label: 'Pending Resolution',
  },
  {
    name: 'PENDING_CERT',
    label: 'Pending Cert',
  },
  {
    name: 'PENDING_ELIGIBILITY',
    label: 'Pending Eligibility',
  },
  {
    name: 'PENDING_REVIEW',
    label: 'Pending Review',
  },
  {
    name: 'EXISTING_LIFELINE_APP',
    label: 'Existing Lifeline APP',
  },
];

export const subscriptionStatuses = [
  {
    name: 'all',
    label: 'All Subscription statuses',
  },
  {
    name: 'canceled',
    label: 'Canceled',
  },
  {
    name: 'past_due',
    label: 'Past Due',
  },
  {
    name: 'incomplete',
    label: 'Incomplete',
  },
  {
    name: 'unpaid',
    label: 'Unpaid',
  },
  {
    name: 'inactive',
    label: 'Inactive',
  },
];

export const eligibilityPayload = (values) => {
  const {
    ssnOrTribal,
    city,
    state,
    BqpSsnOrTribal,
    myselfOrBqp,
    consentInd,
    consentACP,
    tribalId,
    ssn4,
    eligibilityProgramCode,
    eligibilityProgramCodeBqp,
    publicHousingCode,
    publicHousingCodeBqp,
    shippingIsDifferent,
    ...rest
  } = values;

  const shippingAddress = {
    shippingAddress: rest.shippingAddress,
    shippingAddress2: rest.shippingAddress2,
    shippingZipCode: rest.shippingZipCode,
    shippingCity: city || rest.shippingCity,
    shippingState: state || rest.shippingState,
  };

  return {
    ...rest,
    ...(shippingIsDifferent ? {} : shippingAddress),
    ...(ssnOrTribal === 'ssn4' ? { ssn4 } : { tribalId }),
    ...(myselfOrBqp === 'myself'
      ? { eligibilityProgramCode, publicHousingCode }
      : {
          eligibilityProgramCode: eligibilityProgramCodeBqp,
          publicHousingCode: publicHousingCodeBqp,
        }),
    carrierUrl: window.location.origin.includes('localhost')
      ? 'http://www.wrazzle.com'
      : `${window.location.origin}/customers/apply-for-acp`,
    consentInd: consentInd && consentACP ? 'Y' : 'N',
    billingCity: city || rest.billingCity,
    billingState: state || rest.billingState,
    consentTimeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
    consentDateTime: moment().format('YYYY-MM-DD hh:mm:ss'),
  };
};

export const firstSelectOptions = [
  {
    title: 'color',
    label: 'Color',
  },
  {
    title: 'size',
    label: 'Size',
  },
  {
    title: 'material',
    label: 'Material',
  },
  {
    title: 'style',
    label: 'Style',
  },
];

export const PlanCategory = [
  { title: 'ACP', label: 'ACP Lifeline' },
  { title: 'Standard', label: 'Prepaid/Postpaid Plan' },
];

export const APS = [{ title: 6013004, label: 6013004 }];

export const DuringGracePeriod = [
  { title: 'Suspend', label: 'Suspend' },
  { title: 'Deactivate', label: 'Deactivate' },
  { title: 'Activate', label: 'Activate' },
];
export const DuringGracePeriodSettings = [
  { title: 'suspend', label: 'Suspend (Hotline)' },
  { title: 'deactivate', label: 'Deactivate' },
];
export const AfterGracePeriodSettings = [
  { title: 'suspend', label: 'Suspend (Hotline)' },
  { title: 'deactivate', label: 'Deactivate' },
  { title: 'activate', label: 'Activate' },
];

export const ConfigPlan = [
  { title: 'Yes', label: 'Yes', value: true },
  { title: 'No', label: 'No', value: false },
];

export const StatusConfig = [
  { title: 'Active', label: 'Active' },
  { title: 'Dispatched', label: 'Dispatched' },
  { title: 'Inactive', label: 'Inactive' },
];

export const PrepaidOrder = [
  { title: 'On', label: 'On' },
  { title: 'Off', label: 'Off' },
];

export const Days = Array.from({ length: 27 }, (_, index) => {
  const day = (index + 1).toString().padStart(2, '0');
  return { title: day, label: day };
});

export const TypeOfMonth = [{ title: 'End of month', label: 'End of month' }];

export const CalculationType = [
  { title: 'Monthly', label: 'Monthly' },
  { title: 'Annual', label: 'Annual' },
];

export const AutopayDiscountType = [
  { title: 'dollar', label: '$' },
  { title: 'percent', label: '%' },
];

export const AutopayDiscountAmountType = [
  { title: 'Discount on total price', label: 'Discount on total price' },
  { title: 'Per-line auto pay discount', label: 'Per-line auto pay discount' },
];

export const APSTypes = [
  { title: 'Standard', label: 'Standard' },
  { title: 'ACP', label: 'ACP' },
];

export const reportTypes = {
  reportTypeOptions: [
    {
      title: 'summary',
      label: 'Summary',
    },
    {
      title: 'detail',
      label: 'Detail',
    },
  ],
  typeOptions: [
    {
      title: 'enroll',
      label: 'Enroll',
    },
    {
      title: 'transfer',
      label: 'Transfer',
    },
    {
      title: 'update',
      label: 'Update',
    },
    {
      title: 'deenroll',
      label: 'Deenroll',
    },
  ],
  recertificationOptions: [
    {
      title: 'recertified',
      label: 'Recertified',
    },
    {
      title: 'inprogress',
      label: 'Inprogress',
    },
    {
      title: 'deenrolled',
      label: 'Deenrolled',
    },
    {
      title: 'all',
      label: 'All',
    },
  ],
  notProcessedOptions: [
    {
      title: 'usacexclusion',
      label: 'Usacexclusion',
    },
    {
      title: 'tribalexclusion',
      label: 'Tribalexclusion',
    },
    {
      title: 'avporschoollunch',
      label: 'Avporschoollunch',
    },
    {
      title: 'undergoinglifelinerecert',
      label: 'Undergoinglifelinerecert',
    },
    {
      title: 'undergoinglifelinerever',
      label: 'Undergoinglifelinerever',
    },
    {
      title: 'undergoinglifelinece',
      label: 'Undergoinglifelinece',
    },
    {
      title: 'undergoingacpce',
      label: 'Undergoingacpce',
    },
    {
      title: 'all',
      label: 'All',
    },
  ],
};

export const getTotalAmount = (data) => {
  return (
    data.reduce((acc, item) => {
      if (item.variantId) {
        const price = item?.variant?.variantPrices?.[0]?.unit_amount_decimal;

        acc += price * item.count;
      }
      if (item.retailPlanId) {
        acc +=
          +item?.plan?.stripeProduct?.prices?.[0]?.unit_amount_decimal || 0;
      }

      return acc;
    }, 0) / 100
  );
};

export const shippingAmount = (variant) => {
  return (
    variant?.shipping_amount?.amount +
    variant?.insurance_amount?.amount +
    variant?.confirmation_amount?.amount
  );
};

export const totalShippingAmount = (variant) => {
  return variant.isFree ? 0 : shippingAmount(variant);
};

export const variantPrice = (isEligible, variant, type = null) => {
  const prices = variant?.variantPrices || [];

  let productPrice = 'salePrice';
  let priceType = 'one_time';

  if (type === 'monthlyPayment') {
    priceType = 'recurring';
    productPrice = 'costPerMonth';
  } else if (type === 'acp') {
    if (isEligible) {
      productPrice = 'ACPCopay';
      priceType = 'acp_copay_one_time';
    } else {
      productPrice = 'ACPPrice';
      priceType = 'acp_price_one_time';
    }
  }

  let current = {};
  let defaultPrice = {};
  const mainPrice = variant[productPrice];
  for (const price of prices) {
    if (priceType === price.type) {
      current = price;
      current.productPrice = mainPrice;
    } else if ('one_time' === price.type) {
      defaultPrice = price;
      defaultPrice.productPrice = variant.salePrice || variant.retailPrice;
    }
  }

  return mainPrice ? current || defaultPrice : defaultPrice;
};

export const updateShippingDetails = (
  data,
  products,
  type = '',
  customerId = null
) => {
  const {
    id,
    firstName,
    lastName,
    billingAddress: shippingAddress,
    billingAddress2: shippingAddress2,
    billingState: shippingState,
    billingCity: shippingCity,
    billingZipCode: shippingZipCode,
    phone,
    contactPhoneNumber,
  } = data;

  let productsDetails = [];

  if (type === 'tenants-order') {
    if (!products?.simCard || isObjectHasLength(products?.products)) {
      productsDetails = [
        {
          id: products.products.productId,
          quantity: 1,
        },
      ];
    }
  } else {
    productsDetails = products
      .filter((i) => !!i?.variantId)
      .reduce((acc, i) => {
        const variantId = i.variant?.product?.id;
        acc[variantId] = {
          id: variantId,
          quantity: acc[variantId]?.quantity
            ? acc[variantId]?.quantity + i.count
            : i.count,
        };

        return acc;
      }, {});
  }

  const simCards =
    type === 'tenants-order'
      ? [
          {
            id: 'simCard',
            quantity: 1,
          },
        ]
      : products.filter((i) => !!i?.retailPlanId && i.simType === 'psim');

  return {
    toAddress: {
      name: `${firstName} ${lastName}`,
      company_name: '',
      phone: contactPhoneNumber || phone,
      address_line1: shippingAddress,
      address_line2: shippingAddress2,
      city_locality: shippingCity,
      state_province: shippingState,
      postal_code: shippingZipCode,
    },
    isCustomer: false,
    customerId: id ?? customerId,
    products: productsDetails
      ? Object.values(productsDetails)
      : [
          {
            id: products.realProductId,
            quantity: 1,
          },
        ],
    simCardData: [
      { id: 'simCard', quantity: simCards.length === 0 ? 1 : simCards.length },
    ],
  };
};

export const customerOrderDetails = (data) => {
  const { customerId, toAddress, rateId, totalAmount, shippingPrice } = data;

  return {
    shippingAddress1:
      toAddress?.address_line1 || data.shippingAddress || data.billingAddress,
    shippingAddress2:
      toAddress?.address_line2 || data.shippingAddress2 || data.billingAddress2,
    shippingState:
      toAddress?.state_province || data.shippingState || data.billingState,
    shippingCity:
      toAddress?.city_locality || data.shippingCity || data.billingCity,
    shippingZipCode:
      toAddress?.postal_code || data.shippingZipCode || data.billingZipCode,
    companyName: toAddress?.company_name,
    customerPay: totalAmount,
    rate_id: rateId,
    shippingPrice,
    customerId: customerId || data.id,
  };
};

export const getCardLogo = (brand) => {
  switch (brand) {
    case 'visa':
      return visaLogo;
    case 'mastercard':
      return masterCardLogo;
    case 'amex':
      return amexLogo;
    case 'discover':
      return discoverLogo;
    case 'diners':
      return dinersClubLogo;
    case 'jcb':
      return jcbLogo;
    case 'unionpay':
      return unionPayLogo;
    default:
      return visaLogo;
  }
};

export const startsOptions = [
  { title: 'noStar', label: 'No Star' },
  { title: '1', label: '1 Star' },
  { title: '2', label: '2 Star' },
  { title: '3', label: '3 Star' },
  { title: '4', label: '4 Star' },
  { title: '5', label: '5 Star' },
  { title: '6', label: '6 Star' },
];

export const defaultVariant = {
  id: UIDV4(),
  values: { size: '32GB', color: 'black', colorCode: '#000000' },
  salePrice: 0,
  costPerItem: 0,
  shopLocation: '',
  whenOutOfStock: false,
  hasSKUBarcode: false,
  retailPrice: 0,
  months: null,
  ACPPrice: null,
  ACPCopay: null,
  costPerMonth: null,
  ACPReimbursement: null,
  chargeTax: false,
  monthlyPaymentSystem: false,
  ACPQualified: 'No',
  trackQuantity: false,
  acpProfitPercent: null,
  acpProfit: null,
  acpMargin: null,
  profitPercent: null,
  profit: null,
  margin: null,
  monthsProfit: null,
  monthsProfitPercent: null,
  monthsProfitMargin: null,
  totalPayment: null,
};

export const groupVariantsByColor = (variants, groupBy) => {
  const groupedVariants = {};

  switch (groupBy) {
    case 'color': {
      variants.forEach((variant) => {
        const { color, colorCode } = variant.values;

        const key = `${color}_${colorCode}`;
        const images = Array.isArray(variant.Attachments)
          ? variant.Attachments
          : Array.isArray(variant.files)
          ? variant?.files
          : [];
        if (!groupedVariants[key]) {
          groupedVariants[key] = {
            rows: [variant],
            values: variant.values,
            id: UIDV4(),
            images,
          };
        } else {
          groupedVariants[key].rows.push(variant);
          groupedVariants[key].images = [
            ...groupedVariants[key].images,
            ...images,
          ];
        }
      });

      return Object.values(groupedVariants);
    }
    case 'size': {
      variants.forEach((variant) => {
        const { size } = variant.values;

        if (!groupedVariants[size]) {
          groupedVariants[size] = {
            rows: [variant],
            values: variant.values,
            id: UIDV4(),
          };
        } else {
          groupedVariants[size].rows.push(variant);
        }
      });

      return Object.values(groupedVariants);
    }
    default:
      return [];
  }
};

export const webhookEvents = [
  {
    title: 'invoice.payment_succeeded',
    label: 'invoice.payment_succeeded',
  },
  {
    title: 'invoice.payment_failed',
    label: 'invoice.payment_failed',
  },
  {
    title: 'customer.subscription.deleted',
    label: 'customer.subscription.deleted',
  },
  {
    title: 'customer.subscription.created',
    label: 'customer.subscription.created',
  },
  {
    title: 'customer.subscription.updated',
    label: 'customer.subscription.updated',
  },
  {
    title: 'charge.succeeded',
    label: 'charge.succeeded',
  },
  {
    title: 'checkout.session.completed',
    label: 'checkout.session.completed',
  },
  {
    title: 'customer.subscription.pending_update_expired',
    label: 'customer.subscription.pending_update_expired',
  },
  {
    title: 'charge.refunded',
    label: 'charge.refunded',
  },
  {
    title: 'invoice.voided',
    label: 'invoice.voided',
  },
  {
    title: 'subscription_schedule.expiring',
    label: 'subscription_schedule.expiring',
  },
];

export const packagesSelectOptions = [
  { title: 'medium_flat_rate_box', label: 'Medium flat rate box' },
  { title: 'small_flat_rate_box', label: 'Small flat rate box' },
  { title: 'large_flat_rate_box', label: 'Large flat rate box' },
  { title: 'regional_rate_box_b', label: 'Regional rate box b' },
  { title: 'regional_rate_box_a', label: 'Regional rate box a' },
  { title: 'package', label: 'Package' },
  { title: 'large_package', label: 'Large package' },
  { title: 'flat_rate_envelope', label: 'Flat Rate Envelope' },
  { title: 'flat_rate_legal_envelope', label: 'Flat Rate Legal Envelope' },
  {
    title: 'flat_rate_padded_envelope',
    label: 'Flat Rate Padded Envelope',
  },
  { title: 'large_envelope_or_flat', label: 'Large Envelope or Flat' },
  { title: 'large_flat_rate_box', label: 'Large Flat Rate Box' },
  { title: 'large_package', label: 'Large Package (any side > 12)' },
  { title: 'letter', label: 'Letter' },
  { title: 'medium_flat_rate_box', label: 'Medium Flat Rate Box' },
  { title: 'non_rectangular', label: 'Non Rectangular Package' },
  { title: 'non_rectangular', label: 'Non Rectangular Package' },
  { title: 'thick_envelope', label: 'Thick Envelope' },
  { title: 'ups__express_box_large', label: 'UPS  Express® Box - Large' },
  { title: 'ups_10_kg_box', label: 'UPS 10 KG Box®' },
  { title: 'ups_25_kg_box', label: 'UPS 25 KG Box®' },
  { title: 'ups_express_box', label: 'UPS Express® Box' },
  { title: 'ups_express_box_medium', label: 'UPS Express® Box - Medium' },
  { title: 'ups_express_box_small', label: 'UPS Express® Box - Small' },
  { title: 'ups_express_pak', label: 'UPS Express® Pak' },
  { title: 'ups_letter', label: 'UPS Letter' },
  { title: 'ups_tube', label: 'UPS Tube' },
];

export const confirmationSelectOptions = [
  { title: 'none', label: 'None' },
  { title: 'delivery', label: 'Delivery' },
  { title: 'signature', label: 'Signature' },
  { title: 'adult_signature', label: 'Adult Signature' },
  { title: 'direct_signature', label: 'Direct Signature' },
  { title: 'verbal_confirmation', label: 'Verbal' },
  { title: 'delivery_mailed', label: 'Delivery Mailed' },
];

export const insuranceSelectOptions = [
  { title: 'none', label: 'None' },
  { title: 'parcelGuard', label: 'Parcel Guard' },
  { title: 'carrier', label: 'Carrier' },
  { title: 'external', label: 'External' },
];

const stateAbbreviations = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const getStateAbbreviation = (stateName) => {
  return stateAbbreviations[stateName] || null;
};

export const handleNavigate = (
  {
    to,
    id,
    parentId,
    status,
    orderId,
    deletedAt,
    isActive,
    isCanceled,
    carrierId,
    customerId,
    tiktokOrderId,
  },
  navigate,
  setSearchValue
) => {
  switch (to) {
    case 'Agent':
      navigate(
        `/agents/${!parentId ? 'master-agent' : 'sub-agent'}/edit/${id}`
      );
      break;
    case 'Customer':
      navigate(`/customers/profile/${id}`);
      break;
    case 'PortIn':
      navigate(`/dashboard/ports?searchItem=${id}&status=${status}`);
      break;
    case 'OrderLine':
    case 'OrderHistory':
      navigate(`/e-commerce/orders/customer-order/fulfill-order/${orderId}`);
      break;
    case 'InventoryRequest':
      const requestStatus =
        !deletedAt && !isActive && !isCanceled
          ? 'new'
          : deletedAt && !isActive
          ? 'rejected'
          : deletedAt && isActive
          ? 'fulfilled'
          : isCanceled
          ? 'canceled'
          : '';

      navigate(
        `/e-commerce/orders/agent-order?searchItem=${id}&tab=${requestStatus}`
      );
      break;
    case 'Ticket':
      navigate(`/tickets/list?ticketId=${id}`);
      break;
    case 'SIMCard':
      navigate(`/e-commerce/sim-cards?searchItem=${id}`);
      break;
    case 'Vendor':
    case 'Manufacturer':
    case 'Category':
      navigate(
        `/e-commerce/${
          to === 'Vendor'
            ? 'vendors'
            : to === 'Manufacturer'
            ? 'manufacturers'
            : 'categories'
        }?searchItem=${id}`
      );
      break;
    case 'Product':
      navigate(`/e-commerce/device/edit/${id}`);
      break;
    case 'Variant':
      navigate(`/e-commerce/inventory/variant/${id}`);
      break;
    case 'Promotion':
      navigate(`/e-commerce/promotions?searchItem=${id}`);
      break;
    case 'PaymentMethod':
      !customerId
        ? navigate(`/billing/payment-methods?searchItem=${id}`)
        : navigate(`/customers/profile/${customerId}?goToTab=3`);
      break;
    case 'Payment':
      navigate(`/billing/payments-history?searchItem=${id}`);
      break;
    case 'StripeSubscription':
      navigate(`/billing/subscription-details/${id}`);
      break;
    case 'SpecialUser':
      navigate(`/settings/users-edit/${id}`);
      break;
    case 'Role':
      navigate(`/settings/users-roles?searchItem=${id}`);
      break;
    case 'TicketGroup':
      navigate(`/settings/tickets-groups?searchItem=${id}`);
      break;
    case 'RetailPlan':
    case 'TopUp':
      navigate(
        `/settings/${
          to === 'RetailPlan' ? 'retail-plans' : 'top-up'
        }/edit/${id}`
      );
      break;
    case 'WholesalePlans':
      navigate(`/settings/carrier/${carrierId}/wholesale-plan/edit-plan/${id}`);
      break;
    case 'Carrier':
    case 'ShippingCarrier':
      navigate(`/settings/${to === 'Carrier' ? 'carrier' : 'shipping'}/${id}`);
      break;
    case 'Order':
      navigate(
        `/e-commerce/orders/customer-order?tab=tiktok&searchValue=${tiktokOrderId}`
      );
      break;
  }
  dispatch(globalSearchClear());
  setSearchValue('');
};

export const billingUpdateForm = (options) => ({
  billingAddress: {
    name: 'billingAddress',
    label: 'Billing Address',
    required: true,
    type: 'text',
    errorMsg: 'Billing Address is required.',
    placeholder: 'Enter Billing Address',
  },
  billingAddress2: {
    name: 'billingAddress2',
    label: 'Billing Address 2',
    required: false,
    type: 'text',
    placeholder: 'Enter Billing Address 2',
  },
  billingZipCode: {
    name: 'billingZipCode',
    label: 'Billing Zip',
    required: true,
    type: 'number',
    errorMsg: 'Zip is required.',
    invalidMsg: 'Zip is not valid (e.g. 12345).',
    placeholder: 'Enter Zip Code',
  },
  billingState: {
    name: 'billingState',
    label: 'Billing State',
    required: true,
    type: 'select',
    errorMsg: 'State is required.',
    options: getStateOptions('US'),
    placeholder: 'Select State',
  },
  billingCity: {
    name: 'billingCity',
    label: 'Billing City',
    required: true,
    type: 'select',
    errorMsg: 'City is required.',
    placeholder: 'Select City',
  },
  companyName: {
    name: 'companyName',
    label: 'Company Name',
    required: true,
    type: 'text',
    errorMsg: 'Company name is required.',
    placeholder: 'Enter company name',
  },
  contactEmail: {
    name: 'contactEmail',
    label: 'Contact Email',
    required: false,
    type: 'email',
    placeholder: 'Enter contact email',
  },
  ssn4: {
    name: 'ssn4',
    label: 'SSN4',
    required: false,
    type: 'text',
    placeholder: 'Enter SSN4',
  },
  paymentMethodId: {
    name: 'paymentMethodId',
    label:
      'If you want to save the same address for one of credit card, please select',
    required: false,
    type: 'select',
    options,
    placeholder: 'Select credit card',
  },
});

export const billingUpdateValidationSchema = Yup.object().shape({
  billingAddress: Yup.string()
    .required('Billing address 1 is required!')
    .nullable(),
  billingAddress2: Yup.string().nullable(),
  billingZipCode: Yup.string()
    .required('Billing address 2 is required!')
    .nullable(),
  billingState: Yup.string().required('Billing state is required!').nullable(),
  billingCity: Yup.string().required('Billing city is required!').nullable(),
  companyName: Yup.string().required('Company name is required!').nullable(),
  contactEmail: Yup.string().nullable(),
  ssn4: Yup.string().nullable(),
});

export const ticketsDefaultColumns = {
  OPEN: { title: 1, label: 'Open' },
  IN_PROGRESS: { title: 2, label: 'In Progress' },
  CLOSED: { title: 3, label: 'Closed' },
};

export const titleSX = {
  color: 'var(--day-title, #262626)',
  fontFamily: 'Public Sans',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
};

export const descriptionSX = {
  color: 'var(--character-secondary, #8C8C8C)',
  fontFamily: 'Public Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '22px',
};

export const getDefaultBannerElements = (
  fontSize = { lg: '42px', md: '24px' },
  textAlign = { xs: 'center', md: 'start' }
) => {
  const buttonStyle = {
    display: 'inline-flex;',
    '-webkit-box-align': 'center;',
    'align-items': 'center;',
    '-webkit-box-pack': 'center;',
    'justify-content': 'center;',
    'background-color': 'rgb(228, 10, 64);',
    color: 'rgb(255, 255, 255);',
    cursor: 'pointer;',
    'text-transform': 'uppercase;',
    'font-weight': 'bold;',
    width: '250px;',
    'margin-top': '20px;',
    padding: '14px 54px;',
  };

  const textStyle = {
    margin: '0px;',
    'line-height': '1.57;',
    'font-family': '"Public Sans", sans-serif;',
    color: 'rgb(255, 255, 255);',
    'font-size': fontSize,
    'font-weight': '600;',
    'text-transform': 'uppercase;',
    textAlign,
  };

  return {
    availableElements: [
      {
        id: 1,
        type: 'text',
        content: 'Sample Text',
        style: textStyle,
      },
      {
        id: 2,
        type: 'button',
        content: 'Sample Button',
        link: '#',
        style: buttonStyle,
      },
    ],
    defaultBannerElements: [
      {
        id: UIDV4(),
        type: 'text',
        content: 'Free Phone With Purchase of Plan',
        isDisplaying: true,
        style: textStyle,
      },
      {
        id: UIDV4(),
        type: 'button',
        content: 'GET YOURS NOW',
        link: '/product/34?variantId=197',
        isDisplaying: true,
        style: buttonStyle,
      },
      {
        id: UIDV4(),
        type: 'text',
        content: 'Free Tablet With Purchase of Plan',
        isDisplaying: true,
        style: textStyle,
      },
      {
        id: UIDV4(),
        type: 'button',
        content: 'GET YOURS NOW',
        link: '/product/34?variantId=784',
        isDisplaying: true,
        style: buttonStyle,
      },
      {
        id: UIDV4(),
        type: 'banner',
        isDisplaying: false,
        style: {
          background: `url(${backgroundImageBase64})`,
        },
      },
    ],
  };
};

export const shippingCarriersTracking = [
  {
    label: 'United States Postal Service (USPS)',
    title:
      'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1={tracking_number}',
  },
  {
    label: 'United Parcel Service (UPS)',
    title: 'https://www.ups.com/track?tracknum={tracking_number}',
  },
  {
    label: 'Federal Express (FedEx)',
    title: 'https://www.fedex.com/fedextrack/?tracknumbers={tracking_number}',
  },
  {
    label: 'DHL Express',
    title: 'https://www.dhl.com/en/express/tracking.html?AWB={tracking_number}',
  },
  {
    label: 'Canada Post',
    title:
      'https://www.canadapost-postescanada.ca/track-reperage/en#/search?searchFor={tracking_number}',
  },
  {
    label: 'Australia Post',
    title: 'https://auspost.com.au/mypost/track/#/details/{tracking_number}',
  },
  {
    label: 'Royal Mail',
    title:
      'https://www.royalmail.com/track-your-item#/tracking-results/{tracking_number}',
  },
  {
    label: 'Japan Post',
    title:
      'https://trackings.post.japanpost.jp/services/srv/search/direct?reqCodeNo1={tracking_number}',
  },
  {
    label: 'Hermes',
    title: 'https://www.myhermes.co.uk/track#/{tracking_number}',
  },
  {
    label: 'GLS',
    title:
      'https://gls-group.eu/EN/parcel-tracking?matchcode={tracking_number}',
  },
  {
    label: 'DPD',
    title: 'https://www.dpd.com/tracking/?trackingNumber={tracking_number}',
  },
  {
    label: 'TNT',
    title:
      'https://www.tnt.com/express/en_us/site/shipping/tracking.html?trackingNumber={tracking_number}',
  },
  {
    label: 'OnTrac',
    title:
      'https://www.ontrac.com/track/track.htm?trackNumber={tracking_number}',
  },
  {
    label: 'Yamato Transport',
    title: 'https://toi.yamato.gov.jp/cgi-bin/tneko?number={tracking_number}',
  },
  {
    label: 'Aramex',
    title:
      'https://www.aramex.com/track/track?trackingNumber={tracking_number}',
  },
  {
    label: 'Purolator',
    title:
      'https://www.purolator.com/en/shipping/tracking.page?trackingNumber={tracking_number}',
  },
  {
    label: 'ZTO Express',
    title: 'https://www.zto.com/track/{tracking_number}',
  },
  {
    label: 'SF Express',
    title:
      'https://www.sf-express.com/en/global/track/track/?trackNumber={tracking_number}',
  },
  {
    label: 'Sagawa Express',
    title:
      'https://track.sagawa-exp.co.jp/services/tracking/?trackingNumber={tracking_number}',
  },
  {
    label: 'PostNL',
    title:
      'https://www.postnl.nl/en/track-and-trace/?track-and-trace={tracking_number}',
  },
  {
    label: 'Correos',
    title:
      'https://www.correos.es/ss/Satellite/site/pagina-tracking/detalle?tracking={tracking_number}',
  },
  {
    label: 'La Poste',
    title:
      'https://www.laposte.fr/particulier/outils/suivre-vos-envois?code={tracking_number}',
  },
  {
    label: 'Ninja Van',
    title: 'https://www.ninjavan.com/track/{tracking_number}',
  },
  {
    label: 'Deliv',
    title: 'https://www.deliv.co/track/{tracking_number}',
  },
  {
    label: 'Roadie',
    title: 'https://www.roadie.com/track/{tracking_number}',
  },
  {
    label: 'Lalamove',
    title: 'https://www.lalamove.com/track/{tracking_number}',
  },
  {
    label: 'DHL eCommerce',
    title: 'https://www.dhl.com/en/express/tracking.html?AWB={tracking_number}',
  },
  {
    label: 'Canpar',
    title: 'https://www.canpar.com/en/track?tracking={tracking_number}',
  },
  {
    label: 'Fastway',
    title: 'https://www.fastway.ie/track?trackingNumber={tracking_number}',
  },
  {
    label: 'Sendle',
    title: 'https://www.sendle.com/track/{tracking_number}',
  },
];
